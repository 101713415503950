<template>
    <div class="no-gutters">
        <Sidebar/>

        <div class="page-wrapper">
<!--            <TopBar/>-->

            <div class="nuxt-wrapper container-fluid">
                <nuxt/>
            </div>
        </div>

        <AppDrawer/>
    </div>
</template>

<script>
import Sidebar from '../components/Sidebar'
import TopBar from '../components/TopBar'
import LoaderComponent from '../components/LoaderComponent.vue';
import AppDrawer from '../components/AppDrawer.vue'
import UiSnackbarContainer from "../components/ui/UiSnackbarContainer";


export default {
    name: 'test',
    components: {
        UiSnackbarContainer, Sidebar, TopBar, LoaderComponent, AppDrawer
    }
}
</script>

<style lang="scss">
.click {
    cursor: pointer;
}
</style>
