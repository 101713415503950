<template>
  <div class="verification-progress-header my-4 text-center">
    Account verification step <b>{{ currentLabel }} / {{ qty }}</b>
  </div>
</template>
<script>
import {
  WRS_EMERGENCY_CONTACT_STEP_ID,
  WRS_MEDICAL_QUESTIONNAIRE_STEP_ID,
  WRS_NI_NUMBER_STEP_ID,
  WRS_ONFIDO_VERIFICATION_STEP_ID, WRS_PERSONAL_INFO_STEP_ID,
  WRS_SKILL_PHOTOS_STEP_ID
} from "@/components/WorkerRegistration/constants.ts";

export default {
  name: "VerificationProgressHeader",
  data() {
    return {
      steps: [
        WRS_ONFIDO_VERIFICATION_STEP_ID,
        WRS_NI_NUMBER_STEP_ID,
        WRS_SKILL_PHOTOS_STEP_ID,
        WRS_MEDICAL_QUESTIONNAIRE_STEP_ID,
        WRS_PERSONAL_INFO_STEP_ID,
        WRS_EMERGENCY_CONTACT_STEP_ID,
      ]
    }
  },
  computed: {
    qty() {
      return this.steps.length;
    },
    currentLabel() {
      return 1 + this.steps.indexOf(this.$store.getters["worker-registration/currentStepId"]);
    }
  }
}
</script>
<style>
.verification-progress-header {
  font-size: 16px;
}
</style>
