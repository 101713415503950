export default {
  setNotifications: (state, notifications) => {
    if (notifications) {
      state.notifications = notifications;
    }
  },

  addNotification: (state, notifications) => {
    if (!state.notifications.hasOwnProperty('length')) {
      state.notifications = [];
    }

    state.notifications.unshift(notifications);
  },

  setError: (state, error) => {
    state.error = error;
  },
};
