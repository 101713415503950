export default {
  setConversations: (state, conversations) => {
    state.conversations = conversations;
  },

  setMessages: (state, messages) => {
    state.messages = messages;
  },

  setActive: (state, id) => {
    state.activeConversation = id;
  },

  toggleData: state => {
    state.hasData = true;
  },

  addConversation: (state, conversation) => {
    state.conversations.push(conversation);
  },

  addConversations: (state, conversations) => {
    state.conversations = [...state.conversations, ...conversations];
  },

  setTotalCount: (state, total) => {
    state.total = total;
  },

  addMessage: (state, message) => {
    if (
      state.messages &&
      message.conversation_id === state.messages[0].conversation_id
    ) {
      state.messages && state.messages.push(message);
    }
  },

  changeLastMessage: (state, message) => {
    const { content, conversation_id } = message;

    if (state.conversations && state.conversations.hasOwnProperty('length')) {
      state.conversations.map(conversation => {
        if (conversation.id !== conversation_id) {
          return;
        }

        return (conversation.last_message.content = content);
      });
    }
  },

  setUnseenMessages: (state, conversations) => {
    state.unseenMessages = conversations.map(({ id, unseen_count }) => {
      return {
        conversationId: id,
        unseenCount: unseen_count,
      };
    });
  },

  addUnseenMessage: (state, { message, activeConversation }) => {
    if (activeConversation && message.conversation_id === activeConversation) {
      return;
    }

    const updatedUnseenMessages = state.unseenMessages.map(unseenMessage => {
      const { conversationId, unseenCount } = unseenMessage;

      if (conversationId === message.conversation_id) {
        return {
          conversationId,
          unseenCount: unseenCount + 1,
        };
      }

      return unseenMessage;
    });

    state.unseenMessages = updatedUnseenMessages;
  },

  clearUnseen: (state, id) => {
    const updatedUnseenMessages = state.unseenMessages.map(message => {
      const { conversationId } = message;

      if (conversationId === id) {
        return {
          conversationId,
          unseenCount: 0,
        };
      }
      return message;
    });

    state.unseenMessages = updatedUnseenMessages;
  },
};
