/**
 * Calling commit by $nuxt.$store to make sure any usage will be valid.
 */

export const enableLoader = () => {
  $nuxt.$store.commit('loader/enableLoader', null, { root: true });
}
export const disableLoader = () => {
  $nuxt.$store.commit('loader/disableLoader', null, { root: true });
}
