export default {
  setContracts: (state, contracts) => {
    state.contracts = contracts;
  },

  addContracts: (state, contracts) => {
    state.contracts = state.contracts.concat(contracts);
  },

  setContract: (state, contract) => {
    state.contract = contract;
  },

  setLinks: (state, links) => {
    state.links = links;
  },

  setMeta: (state, meta) => {
    state.meta = meta;
  },

  toggleData: state => {
    state.hasData = true;
  },

  setError: (state, error) => {
    state.error = error;
  },
};
