
import { EventBus } from '~/plugins/event-bus';
export default {
    async fetch({commit}, payload) {
        try {
            const widgets = await this.$axios.get(`/api/me/widgets/${payload.route}`,{
                ...payload.site_id
            });
            
            commit('setWidgets', widgets.data.data);
        } catch (error) {
         
            commit('setError', error);
        }
    },

    async save({commit}, formData) {
        try {
            const response = this.$axios.post(`/api/me/widgets/${formData.route}`, {
                ...formData.data
            })
            response.then((res)=>{
                commit('setWidgets', res.data.data);
            }).catch((err)=>{ 
               commit('setError', err)
            })
        } catch (error) {
            commit('setError', error);
        }
    },

    async delete({commit}, formData) {
        try {
            const response = this.$axios.post(`/api/me/widgets/delete`, {
                ...formData
            })
            response.then((res)=>{
                commit('setWidgets', res.data.data);
            })
        } catch (error) {
            commit('setError', error)
        }
    },

    async downloadExcel({commit}, formData) {
        try {
            const response = await this.$axios.post(`/api/me/widgets/download`, {
                ...formData
            })
            commit('setExcelUrl', response.data.data.url);
            
           
        } catch (error) {
            EventBus.$emit('createSnackbar', {
                message: error.response.data.message,
            });
        }
    }
}