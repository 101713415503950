<template>
  <popover
    :name="name"
    class="dropdown--wrapper"
    :width="width"
    :style="styles ? styles : {}"
  >
    <slot></slot>
  </popover>
</template>

<script>
import Vue from 'vue';
import Popover from 'vue-js-popover';

Vue.use(Popover);

export default {
  components: {},
  props: {
    name: {
      type: String,
      default: '',
    },
    title: String,
    width: {
      type: Number,
      default: 180,
    },
    styles: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/global_vars';

.dropdown--wrapper[data-popover] {
  border-radius: 3px;
  padding: 10px;
}
</style>
