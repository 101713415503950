<template>
    <transition name="slide-fade" mode="out-in">
        <div class="popup" :class="active" v-if="isActive">
            <div class="popup-wrapper" v-if="isActive">
                <span class="close" @click="close()">
                    <i class="icon x"></i>
                </span>
                <slot></slot>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    data() {
        return {
            isActive: false
        }
    },
    computed: {
        active() {
            return {
                'active': this.isActive
            }
        }
    },
    methods: {
        open() {
            this.isActive = true;
        },
        close() {
            this.isActive = false;
        }
    }
}
</script>
<style lang="scss" scoped>
    @import '../../assets/scss/_global_vars.scss';
    .popup {
        display: none;
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(29, 29, 29, 0.3);
        top: 0;
        left: 0;
        z-index: 10000;
        padding-top: 154px;
        &.active {
            display: flex;
            justify-content: center;
        }
        &-wrapper {
            max-width: 634px;
            width: 100%;
            border: 1px solid $grey-border;
            border-radius: 8px;
            padding: 32px;
            background-color: #fff;
            position: relative;
            display: flex;
            justify-content: space-between;
            position: absolute;
            flex-wrap: wrap;
            .close {
                position: absolute;
                top: 24px;
                right: 24px;
                cursor: pointer;
            }
        }
    }
    .slide-fade-enter-active {
        transition: all .3s ease;
    }
    .slide-fade-leave-active {
        transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to {
        transform: translateY(-50px);
        opacity: 0;
    }
    @media (max-width: 680px) {
        .invite-popup.active {
            .popup-wrapper {
                justify-content: center;
                max-width: calc(100% - 50px);
            }
        }
    }
</style>