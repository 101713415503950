<template>
  <div>
    <h2 class="offer-title">
      Offer to {{ config.first_name }} {{ config.last_name }}
    </h2>

    <div
      class="d-flex offer-subtitle"
      style="justify-content: space-between; align-items: center;"
    >
      <h3 class="mb-0">Common information</h3>
      <UiCheckbox v-model="job_preset.use">Use preset</UiCheckbox>
    </div>

    <div class="row" v-if="job_preset.use">
      <div class="col">
        <UiSelect
          v-model="job_preset.value"
          @select="handlePresetSelect"
          :options="jobs"
          :keys="{ label: 'label', value: 'id' }"
        >
          Choose Job Preset (Role / Created at)
        </UiSelect>
        <hr />
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-12">
        <FormUiElement :data="form.role_id" :options="roles"
          >Role*</FormUiElement
        >
      </div>

      <div class="col-md-6 col-12">
        <FormUiElement :data="form.due_date">Offer Due Date*</FormUiElement>
      </div>

      <div class="col-md-6 col-12">
        <FormUiElement :data="form.start_at"
          >Contract Start Date*</FormUiElement
        >
      </div>

      <div class="col-md-6 col-12">
        <FormUiElement :data="form.end_at">Contract End Date*</FormUiElement>
      </div>

      <div class="col-md-6 col-12">
        <FormUiElement :data="form.site_id" :options="sites"
          >Site</FormUiElement
        >
      </div>

      <div class="col-md-6 col-12" v-if="hasSite">
        <FormUiElement
          :data="form.manager_id"
          :disabled="!form.site_id.value"
          :options="managers"
          >Manager for approval*
        </FormUiElement>
      </div>

      <div class="col-12">
        <div style="margin-top: 40px;">
          <FormUiElement :data="form.use_timesheet"
            >Generate Timesheets</FormUiElement
          >
        </div>
      </div>
    </div>

    <div v-if="form.use_timesheet.value">
      <h3 class="offer-subtitle">Payment information</h3>
      <div class="row">
        <div class="col-md-6 col-12">
          <FormUiElement :data="form.payment_cycle" :options="paymentCycles"
            >Payment Cycle*</FormUiElement
          >
        </div>
        <div class="col-md-6 col-12">
          <FormUiElement :data="form.hourly_rate" :min="0"
            >Hourly Rate*</FormUiElement
          >
        </div>
        <div class="col-12 d-flex align-items-center">
          <FormUiElement :data="form.payment_type"></FormUiElement>
          <InfoPopup style="margin-top: 12px; margin-left: 10px;">
            If you select ABCPay then timesheets will be sent directly to this
            payroll company once approved by the Manager. If the are not
            registered with this payroll company they will make contact the
            first time you select ABCPay.
          </InfoPopup>
        </div>
        <div class="col-md-6 col-12" v-if="'OWN' === form.payment_type.value">
          <FormUiElement :data="form.email_for_invoice"
            >Email for Invoicing*</FormUiElement
          >
        </div>
      </div>
    </div>

    <div class="row button-wrapper">
      <div class="col-md-6 col-12">
        <UiButton @click="handleCreateOffer()"
          >Create and Send Invitation</UiButton
        >
      </div>
      <div class="col-md-6 col-12">
        <UiButton @click="cancelCreateOffer()" type="secondary"
          >Cancel</UiButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import FormUiElement from '@/components/forms/FormUiElement';
import InfoPopup from '@/components/InfoPopup';
import formHandler from '@/mixins/formHandler';
import { EventBus } from '~/plugins/event-bus';
import moment from 'moment';

export default {
  name: 'CreateJobOfferForm',
  components: { FormUiElement, InfoPopup },
  mixins: [formHandler],
  props: ['config', 'preset'],
  mounted() {
    this.$store.dispatch('sites/init');
    this.$store.dispatch('managers/init');
    this.$store.dispatch('jobs/init');

    this.form.start_at.minDate = this.dayAfter();
    this.form.end_at.minDate = this.dayAfter();
    this.form.due_date.minDate = this.dayAfter();

    if (this.preset) {
      this.handleSeedForm(this.preset, [
        'hourly_rate',
        'role_id',
        'start_at',
        'end_at',
        'site_id',
        'manager_id',
      ]);
    }
  },
  data: () => {
    return {
      job_preset: {
        use: false,
        value: null,
      },
      form: {
        site_id: {
          selector: 'UiSelect',
          errors: null,
          value: null,
          keys: { label: 'name', value: 'id' },
          hasSearch: true,
        },
        role_id: {
          selector: 'UiSelect',
          errors: null,
          value: null,
          keys: { label: 'value', value: 'id' },
          hasSearch: true,
        },
        start_at: {
          selector: 'UiDatepicker',
          errors: null,
          value: null,
          minDate: new Date(),
        },
        end_at: {
          selector: 'UiDatepicker',
          errors: null,
          value: null,
          minDate: new Date(),
        },
        due_date: {
          selector: 'UiDatepicker',
          errors: null,
          value: null,
          minDate: new Date(),
        },
        use_timesheet: {
          selector: 'UiCheckbox',
          errors: null,
          value: false,
        },
        manager_id: {
          selector: 'UiSelect',
          keys: { label: 'name', value: 'id' },
          errors: null,
          value: null,
        },
        payment_cycle: {
          selector: 'UiSelect',
          keys: { label: 'value', value: 'id' },
          errors: null,
          value: null,
        },
        hourly_rate: {
          selector: 'UiTextbox',
          type: 'number',
          errors: null,
          value: null,
        },
        payment_type: {
          selector: 'UiRadioGroup',
          name: 'payment_type',
          key: 'payment_type',
          options: [
            {
              label: 'Use ABC Pay',
              value: 'ABC',
            },
            {
              label: 'Use My own Payroll System',
              value: 'OWN',
            },
          ],
          errors: null,
          value: 'ABC',
        },
        email_for_invoice: {
          selector: 'UiTextbox',
          key: 'email_for_invoice',
          errors: null,
          value: null,
        },
      },
    };
  },
  computed: {
    jobs() {
      return this.$store.getters['jobs/getJobs'].map(job => {
        job.label = `${job.role.value} / ${job.created_at}`;
        return job;
      });
    },

    hasSite() {
      return !!this.form.site_id.value;
    },

    formError() {
      return this.$store.getters['jobOffer/getError'];
    },

    sites() {
      return [
        { name: 'None', id: null },
        ...this.$store.getters['sites/getActiveSites'],
      ];
    },

    roles() {
      return this.$store.getters['dict/getRoles'];
    },

    company() {
      return this.$store.getters['companies/getCompany'];
    },

    paymentCycles() {
      return this.$store.getters['dict/getPaymentCycle'];
    },

    managers() {
      if (!this.sites.length) {
        return [];
      }
      let managers = [];
      const site = this.sites.filter(site => {
        return site.id === this.form.site_id.value;
      });
      if (site.length) {
        managers = site[0].owners.concat(site[0].managers);
      } else {
        managers = this.company.owners.concat(this.company.managers);
      }
      managers = managers.map(({ first_name, last_name, id }) => {
        return {
          name: `${first_name} ${last_name}`,
          id: id,
        };
      });

      // this.form.manager_id.value = null;

      return managers;
    },
  },
  methods: {
    handlePresetSelect(e) {
      if (e.id) {
        this.handleSeedForm(e);
      }
    },

    handleSeedForm(
      preset,
      fields = ['role_id', 'start_at', 'end_at', 'site_id']
    ) {
      fields.forEach(field => {
        if (field === 'site_id') {
          const site = this.sites.find(s => s.id === preset[field]);
          if (site) {
            this.form[field].value = preset[field];
          }
        } else if (field === 'start_at' || field === 'end_at') {
          this.form[field].value = moment(preset[field]).format('YYYY-MM-DD');
        } else {
          this.form[field].value = preset[field];
        }
      });

      if (fields.indexOf('manager_id') === -1) {
        this.form.manager_id.value = null;
      }
    },

    dayAfter(date = new Date()) {
      return moment().endOf('day').toDate();
    },

    handleCreateOffer() {
      const form = this.getDataFromForm(this.form);
      Object.keys(form).forEach(item => {
        if (form[item] === null || form[item] === undefined) {
          delete form[item];
        }
      });
      const payload = Object.assign(form, { worker_id: this.config.id });
      this.$store.dispatch('jobOffer/new', payload).then(response => {
        if (response.data) {
          let data = {
            model: {
              name: 'offer',
              id: response.data.data.id,
            },
            snackbar: {
              message: `Job Offer successfully send.`,
            },
            type: 'model',
            userId: this.config.id,
            refresh: this.config.refresh,
          };

          this.$store.dispatch('messenger/createConversation', data);
        }
      });
    },

    cancelCreateOffer() {
      EventBus.$emit('closeDrawer');
    },
  },
  watch: {
    formError(next) {
      if (next) {
        this.handleErrorInfo(next, this.form);
      }
    },

    form: {
      handler: function (next, curr) {
        if (
          next.start_at.value &&
          next.start_at.value !== curr.start_at.value
        ) {
          this.form.end_at.minDate = this.dayAfter(next.start_at.value);
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/global_vars';

.offer {
  &-title {
    font-size: 34px;
    font-weight: $fontBolder;
  }

  &-subtitle {
    margin: 40px 0 0;
    font-size: 22px;
  }
}

.button-wrapper {
  margin-top: 30px;

  button {
    width: 100%;
  }

  button {
    @media (max-width: $mobileWidth) {
      margin-bottom: 10px;
    }
  }
}

.ui-radio__label-text {
  font-size: 10px;
}
</style>
