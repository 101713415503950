import { EventBus } from '~/plugins/event-bus';
import {disableLoader, enableLoader} from "@/helpers/loader";

export default {
  async init({ dispatch, state, commit }) {
    if (!state.hasData) {
      dispatch('fetchConversations');
      commit('toggleData');
    }
  },

  async fetchConversations({ commit, state }, query = '') {
    try {
      const response = await this.$axios.get('/api/me/conversations' + query);
      const { data } = response.data;
      commit('setConversations', data);
      commit('setUnseenMessages', data);
      commit('setTotalCount', response.data.meta.total);
    } catch (err) {}
  },

  async reloadConversations({ commit }, query) {
    try {
      const response = await this.$axios.get('/api/me/conversations' + query);
      const { data } = response.data;
      commit('addConversations', data);
    } catch (err) {}
  },

  async fetchMessages({ commit }, conversationId) {
    try {
      const response = await this.$axios.get(
        `/api/me/conversations/${conversationId}/messages`
      );
      const { data } = response.data;
      commit('setMessages', data.reverse());
    } catch (err) {}
  },

  async createConversation({ commit, dispatch }, data) {
    enableLoader()
    const { userId, type, refresh, snackbar, redirect } = data;
    let message = { type: type };
    let fallbackAction = '';

    switch (type) {
      case 'text':
        message['content'] = data.message;
        fallbackAction = 'sendMessage';
        break;
      case 'model':
        fallbackAction = 'sendModel';
        message['model'] = data.model;
        break;
    }

    let content = {
      recipient: userId,
      message: message,
    };

    try {
      const response = await this.$axios.post(`/api/me/conversations`, content);
      const { data } = response.data;

      if ('model' === type) {
        if (snackbar) {
          EventBus.$emit('createSnackbar', { message: snackbar.message });
        }
        EventBus.$emit('closeDrawer');
      }

      disableLoader()

      if (redirect) {
        this.$router.push('/messages/?id=' + data.conversation_id);
      }
    } catch (err) {
      if (err.response && 303 === err.response.status) {
        await dispatch(fallbackAction, {
          conversationId: err.response.data.data.id,
          content: message.content || message.model,
          refresh: refresh,
          snackbar: snackbar,
        }).then(res => {
          disableLoader()
          if (res.conversation_id && redirect) {
            this.$router.push('/messages?id=' + res.conversation_id);
          }
        });
      } else {
        disableLoader()
      }
    }
  },

  async sendMessage({ commit }, data) {
    const { conversationId, content, refresh } = data;

    try {
      const response = await this.$axios.post(
        `/api/me/conversations/${conversationId}/messages`,
        {
          type: 'text',
          content,
        }
      );
      const { data } = response.data;

      if (refresh) {
        commit('addMessage', data);
        commit('changeLastMessage', data);
      }

      return data;
    } catch (err) {
      console.error(err);

      return err;
    }
  },

  async sendFile({ commit }, data) {
    enableLoader()
    const { conversationId, attachments } = data;
    let formDataAttachment = new FormData();
    let file = attachments[0];
    formDataAttachment.append('attachment', file, file.name);
    formDataAttachment.append('type', 'attachment');

    try {
      const response = await this.$axios({
        method: 'post',
        url: `/api/me/conversations/${conversationId}/messages`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formDataAttachment,
      });
      const { data } = response.data;
      commit('addMessage', data);
      commit('changeLastMessage', data);
    } catch (err) {
      console.error(err);
    }
    disableLoader()
  },

  async sendModel({ commit, state }, data) {
    enableLoader()
    const { conversationId, content, snackbar } = data;
    try {
      const response = await this.$axios.post(
        `/api/me/conversations/${conversationId}/messages`,
        {
          type: 'model',
          model: content,
        }
      );
      const { data } = response.data;
      commit('addMessage', data);
      commit('changeLastMessage', data);
      if (snackbar) {
        EventBus.$emit('createSnackbar', { message: snackbar.message });
      }
      EventBus.$emit('closeDrawer');
      disableLoader()
      return data;
    } catch (err) {
      console.error(err);
      disableLoader()
      return err;
    }
  },

  async markRead({ commit, state }, conversationId) {
    const appropriateConversation = state.conversations.find(item => {
      return item.id === conversationId;
    });

    if (!appropriateConversation.unseen_count) {
      return;
    }
    const appropriateMessage =
      state.messages[appropriateConversation.unseen_count - 1];

    try {
      await this.$axios.put(
        `/api/me/conversations/${conversationId}/messages/mark-read`,
        {
          timestamp: appropriateMessage['created_at'],
        }
      );
      commit('clearUnseen', conversationId);
    } catch (err) {
      console.error(err);
    }
  },
};
