export default {
  setPages: (state, pages) => {
    state.pages = pages;
  },
  setPage: (state, page) => {
    state.page = page;
  },
  setPath: (state, path) => {
    state.path = path;
  }
};
