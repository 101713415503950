import { EventBus } from '~/plugins/event-bus';
import {disableLoader, enableLoader} from "@/helpers/loader";

export default {
  async init({ dispatch, state }) {
    if (!state.hasData) {
      dispatch('fetch');
    }
  },

  async fetch({ commit }, payload) {
    try {
      const response = await this.$axios.get('/api/job-posts', {
        params: payload.query,
      });
      const { data, links, meta } = response.data;
      if (payload.reset) {
        commit('setWorks', data);
      } else {
        commit('addWorks', data);
      }
      commit('setLinks', links);
      commit('setMeta', meta);
      commit('toggleData');
    } catch (err) {}
  },

  async fetchDetails({ commit, state }, workId) {
    commit('setWork', null);
    try {
      const work = await this.$axios.get(`/api/job-posts/${workId}`);
      commit('setWork', work.data.data);
    } catch (err) {}
  },

  async apply({ commit, state }, workId) {
    enableLoader()
    try {
      const work = await this.$axios.put(`/api/job-posts/${workId}/apply`);
      const { conversation_id } = work.data.data;
      this.$router.push('/messages/?id=' + conversation_id);
      EventBus.$emit('createSnackbar', { message: `You have applied for Job` });
    } catch (err) {
      EventBus.$emit('createSnackbar', {
        message: `${err.response.data.message}`,
      });
    }
    disableLoader()
  },
};
