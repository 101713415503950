import Vue from 'vue';

import '../components/ui/bootstrap';
import configure from '../components/ui/configure';
import { startsWith } from '../components/ui/helpers/util';

import UiAlert from '../components/ui/UiAlert.vue';
import UiAutocomplete from '../components/ui/UiAutocomplete.vue';
import UiButton from '../components/ui/UiButton.vue';
import UiButtonNew from '../components/ui/UiButtonNew.vue';
import UiCalendar from '../components/ui/UiCalendar.vue';
import UiCheckbox from '../components/ui/UiCheckbox.vue';
import UiCheckboxGroup from '../components/ui/UiCheckboxGroup.vue';
import UiCloseButton from '../components/ui/UiCloseButton.vue';
import UiCollapsible from '../components/ui/UiCollapsible.vue';
import UiConfirm from '../components/ui/UiConfirm.vue';
import UiDatepicker from '../components/ui/UiDatepicker.vue';
import UiFab from '../components/ui/UiFab.vue';
import UiFileupload from '../components/ui/UiFileupload.vue';
import UiIcon from '../components/ui/UiIcon.vue';
import UiIconButton from '../components/ui/UiIconButton.vue';
import UiMenu from '../components/ui/UiMenu.vue';
import UiModal from '../components/ui/UiModal.vue';
import UiPopover from '../components/ui/UiPopover.vue';
import UiPreloader from '../components/ui/UiPreloader.vue';
import UiProgressCircular from '../components/ui/UiProgressCircular.vue';
import UiProgressLinear from '../components/ui/UiProgressLinear.vue';
import UiRadio from '../components/ui/UiRadio.vue';
import UiRadioGroup from '../components/ui/UiRadioGroup.vue';
import UiRippleInk from '../components/ui/UiRippleInk.vue';
import UiSelect from '../components/ui/UiSelect.vue';
import UiSlider from '../components/ui/UiSlider.vue';
import UiSnackbar from '../components/ui/UiSnackbar.vue';
import UiSnackbarContainer from '../components/ui/UiSnackbarContainer.vue';
import UiSwitch from '../components/ui/UiSwitch.vue';
import UiTab from '../components/ui/UiTab.vue';
import UiTabs from '../components/ui/UiTabs.vue';
import UiTextbox from '../components/ui/UiTextbox.vue';
import UiToolbar from '../components/ui/UiToolbar.vue';
import UiTooltip from '../components/ui/UiTooltip.vue';
import UiDropdown from '../components/ui/UiDropdown.vue';
import UiPopup from '../components/ui/UiPopup.vue';

const components = {
  UiAlert,
  UiAutocomplete,
  UiButton,
  UiButtonNew,
  UiCalendar,
  UiCheckbox,
  UiCheckboxGroup,
  UiCloseButton,
  UiCollapsible,
  UiConfirm,
  UiDatepicker,
  UiFab,
  UiFileupload,
  UiIcon,
  UiIconButton,
  UiMenu,
  UiModal,
  UiPopover,
  UiPreloader,
  UiProgressCircular,
  UiProgressLinear,
  UiRadio,
  UiRadioGroup,
  UiRippleInk,
  UiSelect,
  UiSlider,
  UiSnackbar,
  UiSnackbarContainer,
  UiSwitch,
  UiTab,
  UiTabs,
  UiTextbox,
  UiToolbar,
  UiTooltip,
  UiDropdown,
  UiPopup,
};

Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component);
});
