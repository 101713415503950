export class UploadProcess {
  constructor(file, fileIndex, fieldName, http, onProgress) {
    this.endBytes = 0;
    this.fail = false;
    this.fieldName = fieldName;
    this.file = file;
    this.fileIndex = fileIndex;
    this.http = http;
    this.onProgress = onProgress;
    this.sending = false;
    this.size = file.size;
    this.sliceSize = parseInt(process.env.UPLOAD_CHUNK_SIZE || 1048576);
    this.startBytes = 0;

    return new Promise((resolve, reject) => {
      this.$promiseRef = {
        resolve: resolve,
        reject: reject,
      };

      this.sending = true;
      this._process();
    });
  }

  get progress() {
    if (!this.sending) {
      return 0;
    }

    return Math.round((this.endBytes / this.size) * 100);
  }

  _markAsFailed(err) {
    this.fail = true;
    this.sending = false;

    this.$promiseRef.reject(err.response ? err.response.data : err);
  }

  _markAsFinished(res) {
    this.fail = false;
    this.sending = false;

    this.$promiseRef.resolve(res.data.data);
  }

  _notifyProgress() {
    this.onProgress(this.fileIndex, {
      totalSize: this.size,
      transferedBytes: this.endBytes,
    });
  }

  _process() {
    this.endBytes = this.startBytes + this.sliceSize;

    if (this.size - this.endBytes < 0) {
      this.endBytes = this.size;
    }

    const slice = this._sliceFile(this.file);

    let formData = new FormData();
    formData.append('file_key', this.fieldName);
    formData.append(
      this.fieldName,
      new File([slice], this.file.name, { type: this.file.type })
    );

    this._uploadChunk(
      formData,
      `${this.startBytes}-${this.endBytes}/${this.size}`
    ).then(status => {
      this._notifyProgress();

      if (status.done) {
        this.startBytes += this.sliceSize;

        if (this.endBytes < this.size) {
          setTimeout(this._process.bind(this), 1);
        } else {
          this._markAsFinished(status.response);
        }
      } else {
        const err = status.error;
        if (
          err &&
          err.response &&
          err.response.status === 429 &&
          typeof err.response.headers['x-ratelimit-reset'] !== 'undefined'
        ) {
          // Rate limiting prevention with minimum "pause" duration of 1 sec
          const timeout =
            Math.abs(
              parseInt(err.response.headers['x-ratelimit-reset'] + '000') -
                new Date().getTime()
            ) + 1000;

          setTimeout(this._process.bind(this), timeout);
        } else {
          this._markAsFailed(status.error);
        }
      }
    });
  }

  _sliceFake() {}

  _sliceFile(file) {
    const slice = file.mozSlice
      ? file.mozSlice
      : file.webkitSlice
      ? file.webkitSlice
      : file.slice
      ? file.slice
      : this._sliceFake;

    return slice.bind(file)(this.startBytes, this.endBytes);
  }

  async _uploadChunk(form, range) {
    try {
      if ($nuxt.$store.getters['media/getCanceled'] !== this.fileIndex) {
        const res = await this.http({
          method: 'post',
          url: '/api/media/upload-chunked',
          data: form,
          headers: {
            'content-type': 'miltipart/form-data',
            'content-range': `bytes ${range}`,
          },
          onUploadProgress: (e) => {
            $nuxt.$store.commit('media/uploading' , { id: this.fileIndex, progress: Math.round((this.endBytes / this.size) * 100) });
          }
        })
        return { done: true, response: res };
      } else { 
        const error = {
          response: {
            data: {
              status: 400
            }
          }
        }
        return {done: false, error: error}
      }
    } catch (e) {
      return { done: false, error: e };
    }
  }
}
