import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/account/actions.js'), 'account/actions.js')
  resolveStoreModules(require('../store/account/getters.js'), 'account/getters.js')
  resolveStoreModules(require('../store/account/mutations.js'), 'account/mutations.js')
  resolveStoreModules(require('../store/account/state.js'), 'account/state.js')
  resolveStoreModules(require('../store/assignments/actions.js'), 'assignments/actions.js')
  resolveStoreModules(require('../store/assignments/getters.js'), 'assignments/getters.js')
  resolveStoreModules(require('../store/assignments/mutations.js'), 'assignments/mutations.js')
  resolveStoreModules(require('../store/assignments/state.js'), 'assignments/state.js')
  resolveStoreModules(require('../store/auth/actions.js'), 'auth/actions.js')
  resolveStoreModules(require('../store/auth/getters.js'), 'auth/getters.js')
  resolveStoreModules(require('../store/auth/mutations.js'), 'auth/mutations.js')
  resolveStoreModules(require('../store/auth/state.js'), 'auth/state.js')
  resolveStoreModules(require('../store/billings/actions.js'), 'billings/actions.js')
  resolveStoreModules(require('../store/billings/getters.js'), 'billings/getters.js')
  resolveStoreModules(require('../store/billings/mutations.js'), 'billings/mutations.js')
  resolveStoreModules(require('../store/billings/state.js'), 'billings/state.js')
  resolveStoreModules(require('../store/card/actions.js'), 'card/actions.js')
  resolveStoreModules(require('../store/card/getters.js'), 'card/getters.js')
  resolveStoreModules(require('../store/card/mutations.js'), 'card/mutations.js')
  resolveStoreModules(require('../store/card/state.js'), 'card/state.js')
  resolveStoreModules(require('../store/companies/actions.js'), 'companies/actions.js')
  resolveStoreModules(require('../store/companies/getters.js'), 'companies/getters.js')
  resolveStoreModules(require('../store/companies/mutations.js'), 'companies/mutations.js')
  resolveStoreModules(require('../store/companies/state.js'), 'companies/state.js')
  resolveStoreModules(require('../store/contracts/actions.js'), 'contracts/actions.js')
  resolveStoreModules(require('../store/contracts/getters.js'), 'contracts/getters.js')
  resolveStoreModules(require('../store/contracts/mutations.js'), 'contracts/mutations.js')
  resolveStoreModules(require('../store/contracts/state.js'), 'contracts/state.js')
  resolveStoreModules(require('../store/dict/actions.js'), 'dict/actions.js')
  resolveStoreModules(require('../store/dict/getters.js'), 'dict/getters.js')
  resolveStoreModules(require('../store/dict/mutations.js'), 'dict/mutations.js')
  resolveStoreModules(require('../store/dict/state.js'), 'dict/state.js')
  resolveStoreModules(require('../store/help/actions.js'), 'help/actions.js')
  resolveStoreModules(require('../store/help/getters.js'), 'help/getters.js')
  resolveStoreModules(require('../store/help/mutations.js'), 'help/mutations.js')
  resolveStoreModules(require('../store/help/state.js'), 'help/state.js')
  resolveStoreModules(require('../store/invite/actions.js'), 'invite/actions.js')
  resolveStoreModules(require('../store/invite/getters.js'), 'invite/getters.js')
  resolveStoreModules(require('../store/invite/mutations.js'), 'invite/mutations.js')
  resolveStoreModules(require('../store/invite/state.js'), 'invite/state.js')
  resolveStoreModules(require('../store/jobOffer/actions.js'), 'jobOffer/actions.js')
  resolveStoreModules(require('../store/jobOffer/getters.js'), 'jobOffer/getters.js')
  resolveStoreModules(require('../store/jobOffer/mutations.js'), 'jobOffer/mutations.js')
  resolveStoreModules(require('../store/jobOffer/state.js'), 'jobOffer/state.js')
  resolveStoreModules(require('../store/jobs/actions.js'), 'jobs/actions.js')
  resolveStoreModules(require('../store/jobs/getters.js'), 'jobs/getters.js')
  resolveStoreModules(require('../store/jobs/mutations.js'), 'jobs/mutations.js')
  resolveStoreModules(require('../store/jobs/state.js'), 'jobs/state.js')
  resolveStoreModules(require('../store/loader/getters.js'), 'loader/getters.js')
  resolveStoreModules(require('../store/loader/mutations.js'), 'loader/mutations.js')
  resolveStoreModules(require('../store/loader/state.js'), 'loader/state.js')
  resolveStoreModules(require('../store/managers/actions.js'), 'managers/actions.js')
  resolveStoreModules(require('../store/managers/getters.js'), 'managers/getters.js')
  resolveStoreModules(require('../store/managers/mutations.js'), 'managers/mutations.js')
  resolveStoreModules(require('../store/managers/state.js'), 'managers/state.js')
  resolveStoreModules(require('../store/media/actions.js'), 'media/actions.js')
  resolveStoreModules(require('../store/media/getters.js'), 'media/getters.js')
  resolveStoreModules(require('../store/media/mutations.js'), 'media/mutations.js')
  resolveStoreModules(require('../store/media/state.js'), 'media/state.js')
  resolveStoreModules(require('../store/messenger/actions.js'), 'messenger/actions.js')
  resolveStoreModules(require('../store/messenger/getters.js'), 'messenger/getters.js')
  resolveStoreModules(require('../store/messenger/mutations.js'), 'messenger/mutations.js')
  resolveStoreModules(require('../store/messenger/state.js'), 'messenger/state.js')
  resolveStoreModules(require('../store/notifications/actions.js'), 'notifications/actions.js')
  resolveStoreModules(require('../store/notifications/getters.js'), 'notifications/getters.js')
  resolveStoreModules(require('../store/notifications/mutations.js'), 'notifications/mutations.js')
  resolveStoreModules(require('../store/notifications/state.js'), 'notifications/state.js')
  resolveStoreModules(require('../store/profile/actions.js'), 'profile/actions.js')
  resolveStoreModules(require('../store/profile/getters.js'), 'profile/getters.js')
  resolveStoreModules(require('../store/profile/mutations.js'), 'profile/mutations.js')
  resolveStoreModules(require('../store/profile/state.js'), 'profile/state.js')
  resolveStoreModules(require('../store/search/actions.js'), 'search/actions.js')
  resolveStoreModules(require('../store/search/getters.js'), 'search/getters.js')
  resolveStoreModules(require('../store/search/mutations.js'), 'search/mutations.js')
  resolveStoreModules(require('../store/search/state.js'), 'search/state.js')
  resolveStoreModules(require('../store/site-control/actions.js'), 'site-control/actions.js')
  resolveStoreModules(require('../store/site-control/getters.js'), 'site-control/getters.js')
  resolveStoreModules(require('../store/site-control/mutations.js'), 'site-control/mutations.js')
  resolveStoreModules(require('../store/site-control/state.js'), 'site-control/state.js')
  resolveStoreModules(require('../store/sites/actions.js'), 'sites/actions.js')
  resolveStoreModules(require('../store/sites/getters.js'), 'sites/getters.js')
  resolveStoreModules(require('../store/sites/mutations.js'), 'sites/mutations.js')
  resolveStoreModules(require('../store/sites/state.js'), 'sites/state.js')
  resolveStoreModules(require('../store/sort/getters.js'), 'sort/getters.js')
  resolveStoreModules(require('../store/sort/mutations.js'), 'sort/mutations.js')
  resolveStoreModules(require('../store/sort/state.js'), 'sort/state.js')
  resolveStoreModules(require('../store/timesheets/actions.js'), 'timesheets/actions.js')
  resolveStoreModules(require('../store/timesheets/getters.js'), 'timesheets/getters.js')
  resolveStoreModules(require('../store/timesheets/mutations.js'), 'timesheets/mutations.js')
  resolveStoreModules(require('../store/timesheets/state.js'), 'timesheets/state.js')
  resolveStoreModules(require('../store/visitor/actions.js'), 'visitor/actions.js')
  resolveStoreModules(require('../store/visitor/getters.js'), 'visitor/getters.js')
  resolveStoreModules(require('../store/visitor/mutations.js'), 'visitor/mutations.js')
  resolveStoreModules(require('../store/visitor/state.js'), 'visitor/state.js')
  resolveStoreModules(require('../store/widgets/actions.js'), 'widgets/actions.js')
  resolveStoreModules(require('../store/widgets/getters.js'), 'widgets/getters.js')
  resolveStoreModules(require('../store/widgets/mutations.js'), 'widgets/mutations.js')
  resolveStoreModules(require('../store/widgets/state.js'), 'widgets/state.js')
  resolveStoreModules(require('../store/worker-registration/actions.js'), 'worker-registration/actions.js')
  resolveStoreModules(require('../store/worker-registration/getters.js'), 'worker-registration/getters.js')
  resolveStoreModules(require('../store/worker-registration/mutations.js'), 'worker-registration/mutations.js')
  resolveStoreModules(require('../store/worker-registration/state.js'), 'worker-registration/state.js')
  resolveStoreModules(require('../store/workerProfileEdit/actions.js'), 'workerProfileEdit/actions.js')
  resolveStoreModules(require('../store/workerProfileEdit/getters.js'), 'workerProfileEdit/getters.js')
  resolveStoreModules(require('../store/workerProfileEdit/mutations.js'), 'workerProfileEdit/mutations.js')
  resolveStoreModules(require('../store/workerProfileEdit/state.js'), 'workerProfileEdit/state.js')
  resolveStoreModules(require('../store/workers/actions.js'), 'workers/actions.js')
  resolveStoreModules(require('../store/workers/getters.js'), 'workers/getters.js')
  resolveStoreModules(require('../store/workers/mutations.js'), 'workers/mutations.js')
  resolveStoreModules(require('../store/workers/state.js'), 'workers/state.js')
  resolveStoreModules(require('../store/works/actions.js'), 'works/actions.js')
  resolveStoreModules(require('../store/works/getters.js'), 'works/getters.js')
  resolveStoreModules(require('../store/works/mutations.js'), 'works/mutations.js')
  resolveStoreModules(require('../store/works/state.js'), 'works/state.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
