export default {
  getPages: state => {
    return state.pages;
  },
  getPage: state => {
    return state.page;
  },
  getPath: state => {
    return state.path;
  }
};
