export default {
    setWidgets: (state, data) => {
        state.widgets = data;
    },
    setError: (state, error) => {
        state.error = error;
    },
    setErrorDublicated: (state, error) => {
        state.errorDublicated = error;
    },
    setExcelUrl: (state, url) => {
        state.excelUrl = url;
    }
}