<template>
  <div class="loader" v-show="loaderActive">
    <UiProgressCircular
      :type="loaderProgress === false ? 'indeterminate' : 'determinate'"
      :progress="loaderProgress === false ? 0 : loaderProgress"
      color="brand"
      :size="75"
      :stroke="5"
    />
    <div class="loader-text">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    loaderActive() {
      return this.$store.getters['loader/activeLoader'];
    },
    loaderProgress() {
      return this.$store.getters['loader/loaderProgress'];
    },
  },
  watch: {
    loaderActive(next, curr) {
      // TODO loader smooth transitions
    },
  },
};
</script>

<style lang="scss" scoped>
.loader {
  background: rgba(white, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000000;
  &-text {
    margin-top: 40px;
  }
}
</style>
