import Vue          from 'vue'
import {isDebug}    from "@/helpers/environment";
import LocalStorage from "@/helpers/local-storage";

/**
 * This plugin extends all components by debug helper methods used on local development.
 * WARNING: THIS FILE MUSTNT BE BUNDLED WITH PRODUCTION BUILD. INTERNAL USE ONLY
 *
 * @param context
 * @param inject
 */
export default (context, inject) => {
  /**
   * Component used to display content that should be only visible on DEBUG
   */
  // const ENABLE_DEBUG_VIEW = isDebug();
  const ENABLE_DEBUG_VIEW = false;
  Vue.component('DebugView', {
    render(createElement, context) {

      if (!ENABLE_DEBUG_VIEW) {
        return null;
      }
      return createElement('div', this.$slots.default)
    }
  })
  if (!isDebug()) {
    return;
  }
  Vue.mixin(
    {
      methods: {
        _debugMarkOnfidoAsCompleted() {
          this.$axios.post(process.env.API_URL + '/api/debug-helpers/simulate-onfido-check-completed', {
            applicant_id: this.$store.getters['worker-registration/applicantId']
          })
        },
        _debugMarkOnfidoAsFailed() {
          this.$axios.post(process.env.API_URL + '/api/debug-helpers/simulate-onfido-check-failed', {
            applicant_id: this.$store.getters['worker-registration/applicantId']
          })
        },
        _debugResetFlow() {
          LocalStorage.removeToken()
          window.location.reload();
        },
        _debugLogUserData() {
          console.info(JSON.parse(JSON.stringify(this.$store.state)));
        },

        _debugFetchLastValidCode() {
          this.$axios.get(process.env.API_URL + '/api/debug-helpers/last-verification-code', {
            params: {phone_number: this.phoneNumber}
          })
            .then(({data}) => {
              if (data.code) {
                this.form.code.value = data.code.code;
              } else {
                alert("no valid code found");
              }
            })
        },
        _debugResendCode() {
          this.$axios.post(
            '/api/worker-registration/phone-verification/resend-code',
            {phone_number: this.phoneNumber}
          )
            .then(({data}) => {
              alert("done");
            })
        },
        _debugCreateEmptyWorker() {
          this.$axios.post(process.env.API_URL + '/api/debug-helpers/unfinished-worker-credentials')
            .then(({data}) => {
              const ukPrefix = '+44 ';
              let value = data.user.phone_number;
              value = value.replace(ukPrefix, '');
              this.form.phone_number.value = value;
            })
        },
        _debugFillRandomMedicalQuestionnaire() {
          Object.keys(this.form).forEach(key => {
            const copy = Object.assign({}, this.form[key]);
            copy.value = Math.random() > 0.5 ? 'yes' : 'no';
            copy.options.forEach(option => {
              // trigger html5 validation properly by also touching 'checked'
              option.checked = copy.value === option.value;
            })
            this.form[key] = copy;

          })
        },
        _debugFillRandomPersonalInfo() {
          Object.keys(this.form).forEach(key => {
            const copy = Object.assign({}, this.form[key]);
            switch (key) {
              case 'date_of_birth':
                copy.value = '01/01/2020';
                break;
              case 'email':
                copy.value = Math.random() + '@example.com';
                break;
              default:
                copy.value = Math.random();
            }
            this.form[key] = copy;
          });

        }
      }
    });
}
