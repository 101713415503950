export default {
  setDict: (state, data) => {
    state.data = data;
  },
  setLocations: (state, data) => {
    state.locations = data;
  },
  toggleData: state => {
    state.hasData = true;
  },
};
