
export default {
  async fetch({ commit }) {
    try {
      const notifications = await this.$axios.get('/api/me/notifications');
      const { data } = notifications.data;
      commit('setNotifications', data);
    } catch (err) {
      commit('setError', err);
    }
  },

  add({ commit }, notification) {
    commit('addNotification', notification);
  },

  async markRead({ commit, dispatch }, id) {
    try {
      const markRead = await this.$axios.put(
        `/api/me/notifications/mark-read${id ? '/' + id : ''}`
      );
      dispatch('fetch');
    } catch (err) {
      commit('setError', err);
    }
  },
};
