import { EventBus } from '~/plugins/event-bus';
import {disableLoader, enableLoader} from "@/helpers/loader";

export default {
  async init({ dispatch, state }) {
    if (!state.hasData) {
      dispatch('fetch');
    }
  },

  async fetch({ commit }, payload) {
    try {
      const response = await this.$axios.get('/api/me/workers', {
        params: payload.query,
      });
      
      const { data, links, meta } = response.data;

      if (payload.reset) {
        commit('setWorkers', data);
      } else {
        commit('addWorkers', data);
      }

      commit('setLinks', links);
      commit('setMeta', meta);
      commit('toggleData');
      commit('setClear', false);
    } catch (err) {
      console.log(err);
    }
  },

  async fetchSimpleWorker({ commit }, slug) {
    try {
      const response = await this.$axios.get(
        '/api/me/workers/' + slug + '/simple'
      );
      commit('setSimpleWorker', response.data.data);
    } catch (err) {
      console.log(err);
    }
  },

  async fetchAssignments({ commit }, slug) {
    try {
      let response;
      if (typeof slug === 'object') {
        response = await this.$axios.get(
          '/api/me/workers/' + slug.slug + '/assignments',{
            params: {
              sort: slug.sort
            }
          }
        );
      } else {
        response = await this.$axios.get(
          '/api/me/workers/' + slug + '/assignments'
        );
      }
      commit('setAssignments', response.data.data);
    } catch (err) {
      console.log(err);
    }

  },

  async fetchTimesheets({ commit, state }, payload) {
    try {
      if (payload.slug !== undefined) {
          const response = await this.$axios.get('/api/me/workers/' + payload.slug + '/timesheets', {
            params: {
              sort: payload.sort,
              ...state.filter
            }
          }
        );
        commit('setTimesheets', response.data.data);
      }
      commit('setClear', false);
    } catch (err) {
      console.log(err);
    }
  },
  async fetchFilters({ commit }, slug = null) {
    try {
      const filters = await this.$axios.get(
        '/api/me/workers/' + slug + '/timesheets/filters'
      );
      commit('setFilters', filters.data);
    } catch (err) {
    }
  },
  async fetchContracts({ commit }, slug) {
    try {
      const response = await this.$axios.get(
        '/api/me/workers/' + slug + '/contracts'
      );
      commit('setContracts', response.data.data);
    } catch (err) {
      console.log(err);
    }

  },

  async fetchAll({ commit }) {
    try {
      const response = await this.$axios.get('/api/me/workers/simple');
      return response.data.data;
    } catch (err) {
      console.log(err);
    }
  },

  async fetchAssignable({ commit }) {
    try {
      const response = await this.$axios.get(
        '/api/me/workers/simple?status=ASSIGNABLE'
      );


      return response.data.data;
    } catch (err) {
      console.log(err);
    }
  },

  // async fetchExcelTemplate({commit}) {
  //     enableLoader()
  //
  //     try {
  //         const response = await this.$axios.get('/api/me/workers/excel', {
  //             headers: {
  //                 responseType: 'blob'
  //             }
  //         });
  //
  //         disableLoader()
  //         console.log(response);
  //
  //         saveAs(new Blob([response.data], {type: "**application/octet-stream**"}), 'test.xls');
  //
  //         return response.data;
  //     } catch (err) {
  //         disableLoader()
  //         console.log(err);
  //     }
  // },

  async link({ commit }, payload) {
    enableLoader()
    try {
      const workers = await this.$axios({
        method: 'post',
        url: '/api/me/workers/link',
        data: payload.formData,
      }).then((res) => {
        // commit('setNewWorker', res.data.data.user);
        this.$router.push('/workers');
      });
      EventBus.$emit('createSnackbar', {
        message: 'Worker linked successfully.',
      });
    } catch (err) {
      EventBus.$emit('createSnackbar', {
        message: 'Error during link workers.',
      });
      commit('setError', err);
    }
    disableLoader()
  },

  async batch({ commit }, formData) {
    enableLoader()
    commit('setError', '');
    commit('setBatchInfo', null);
    try {
      const response = await this.$axios({
        method: 'post',
        url: '/api/me/workers/excel',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });

      EventBus.$emit('createSnackbar', {
        message: response.data.msg || 'Batch import success',
      });
      commit('setBatchInfo', response);
    } catch (err) {
      commit('setError', err);
      EventBus.$emit('createSnackbar', { message: err.message });
    }
    disableLoader()
  },
  async batchDelete({commit}, formData ) {
    try {
      const response = await this.$axios({
        method: 'post',
        url: '/api/me/workers/excel/delete', 
        data: formData
      });

    } catch (err) {
      EventBus.$emit('createSnackbar', { message: err.message });
    }
  }
};
