export default () => ({
  hasData: false,
  workers: null,
  links: null,
  meta: null,
  error: '',
  simpleWorker: null,
  assignments: [],
  contracts: [],
  timesheets: [],
  batchInfo: null,
  clear: false,
  companies: null,
  site: null,
  filters: null,
  filter: {},
  newWorker: null
});
