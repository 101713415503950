export default () => ({
    succes: false,
    hasForm: false,
    formData: {},
    visitors: [],
    inviteData: {},
    visitor: null,
    error: null,
    qrPdf: null,
    create: true
});