// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/approval-bg.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".invite-template__wrapper{background-color:#fbfbfd;display:flex;justify-content:center;min-height:100vh;padding-top:86px}.invite-template__nav{display:flex;justify-content:center;padding:0 8vw;position:absolute;top:33px;width:100%}@media (max-width:767px){.invite-template__nav{align-items:center;padding:0 1rem;top:24px}.invite-template__logo{height:26px}}.invite-template .invitation{display:flex;flex-direction:column;margin:0 auto;max-width:500px;padding-bottom:2.5rem;width:100%}.invite-template .invitation--background{position:relative}.invite-template .invitation--background:after{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:center 35vh;background-repeat:no-repeat;content:\"\";min-height:100vh;min-width:100vw;position:fixed;z-index:0}.invite-template .invitation__wrapper{flex-grow:1;position:relative;z-index:1}.invite-template .invitation__buttons{align-items:center;display:flex;flex-direction:column;position:relative;z-index:1}.invite-template .invitation__button{max-width:275px;width:90%}.invite-template .invitation__button--full{background-color:#00a1fe;box-shadow:0 -1px 8px 0 rgba(0,161,254,.39);color:#fff!important;font-size:20px;font-weight:500;height:auto;line-height:1.2;max-width:none;overflow-x:none;padding:20px;position:relative;width:100%}.invite-template .invitation__text{font-size:20px;font-weight:400;line-height:30px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
