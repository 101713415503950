<template>
  <div class="view-offer" :class="{ expired: isExpired }">
    <img
      src="~/assets/images/billing/x.svg"
      alt="X"
      style="height: 20px; float: right; cursor: pointer;"
      @click="closeOffer"
    />

    <div class="expired-message" v-if="isExpired && isOffer">
      Job Offer expired
    </div>

    <h2 class="view-offer-title">
      {{ isOffer ? 'Offer' : 'Job Post' }}

      <span v-if="!isWorker">
        for {{ config.recipient.first_name }} {{ config.recipient.last_name }}
      </span>

      <span v-if="isWorker">
        from {{ config.recipient.first_name }} {{ config.recipient.last_name }}
      </span>
    </h2>

    <h3>General information</h3>

    <div class="row">
      <div class="col-6" v-if="config.data.model.site">
        <FakeInput label="Address">
          {{ config.data.model.site.address_line_1 }}
        </FakeInput>
      </div>

      <div class="col-6" v-if="config.data.model.site">
        <FakeInput label="Site">
          <span class="capitalize">
            {{ config.data.model.site.name }}
          </span>
        </FakeInput>
      </div>

      <div class="col-6" v-if="config.data.model.role_id">
        <FakeInput label="Job">
          {{ matchRole(config.data.model.role_id) }}
        </FakeInput>
      </div>

      <div class="col-6" v-if="config.data.model.manager">
        <FakeInput label="Manager">
          {{
            `${config.data.model.manager.first_name} ${config.data.model.manager.last_name}`
          }}
        </FakeInput>
      </div>

      <div class="col-6">
        <FakeInput label="Employment Start Date">
          {{ getDate(config.data.model.start_at, 'D MMMM YYYY', false) }}
        </FakeInput>
      </div>

      <div class="col-6">
        <FakeInput label="Employment End Date">
          {{ getDate(config.data.model.end_at, 'D MMMM YYYY', false) }}
        </FakeInput>
      </div>

      <div class="col-6" v-if="isOffer">
        <FakeInput label="Offer Due Date">
          {{ getDate(config.data.model.due_date, 'D MMMM YYYY', false) }}
        </FakeInput>
      </div>

      <div class="col-6" v-if="config.data.model.payment_cycle">
        <FakeInput label="Payment Cycle">
          <span class="capitalize">
            {{ config.data.model.payment_cycle.toLowerCase() }}
          </span>
        </FakeInput>
      </div>

      <div class="col-6" v-if="config.data.model.hourly_rate">
        <FakeInput label="Hourly Rate">
          {{ config.data.model.hourly_rate }}
        </FakeInput>
      </div>

      <div class="col-6" v-if="config.data.model.payment_type">
        <FakeInput label="Payment method">
          {{ config.data.model.payment_type }}
        </FakeInput>
      </div>

      <div class="col-6" v-if="isPost">
        <FakeInput label="Ref Number">
          {{ config.data.model.ref_number }}
        </FakeInput>
      </div>

      <div class="col-6" v-if="isPost">
        <FakeInput label="Employment Type" class="capitalize">
          {{ makeReadable(config.data.model.employment_type) }}
        </FakeInput>
      </div>

      <div class="col-6" v-if="isPost">
        <FakeInput label="Experience Years">
          {{ config.data.model.experience_years }}
        </FakeInput>
      </div>
    </div>

    <div v-if="isPost">
      <FakeInput label="Description">
        {{ config.data.model.description }}
      </FakeInput>
    </div>

    <div v-if="isOffer">
      <div class="rams-header d-flex flex-center">
        <h3 style="margin-bottom: 0; margin-right: 2rem; line-height: 1em;">
          Documents & RAM’s
        </h3>
        <UiCheckbox
          v-if="isWorker && !(isRejected || isAccepted || isExpired)"
          v-model="rams_accepted"
        >
          Accept Doc's
        </UiCheckbox>
      </div>

      <div v-if="rams" class="rams">
        <div v-for="ram in rams" class="ram-wrapper">
          <a :href="ram.full_url" class="ram" target="_blank">
            <img
              :src="placeholder"
              class="ram-image"
              style="width: 26px;"
              :alt="ram.file_name"
            />
            <span class="ram-title">
              {{ ram.file_name }}
            </span>
          </a>
        </div>
      </div>

      <div v-if="!rams" class="my-3">
        No rams
      </div>

      <div class="alert" v-if="errors">{{ errors }}</div>

      <div v-if="isWorker && !isExpired && !(isRejected || isAccepted)">
        <UiButton @click="handleAccept()" class="mb-2">
          Accept Offer
        </UiButton>

        <UiButton @click="handleDecline()" class="mb-2" type="secondary">
          Decline Offer
        </UiButton>
      </div>
    </div>

    <div v-if="isPost && !isWorker">
      <UiButton class="mt-3" @click="handleSendOffer()">
        Send Offer
      </UiButton>
    </div>
  </div>
</template>

<script>
import FakeInput from '@/components/FakeInput';
import momentHandler from '~/mixins/momentHandler';
import { EventBus } from '~/plugins/event-bus';

export default {
  props: ['config'],
  components: { FakeInput },
  mixins: [momentHandler],
  data() {
    return {
      rams_accepted: false,
      placeholder: require('../../assets/images/file-icon-generic.svg'),
    };
  },
  mounted() {
    // this.$store.dispatch('sites/init');
    // this.$store.dispatch('managers/init');
    this.$store.commit('jobOffer/clearError');
  },
  methods: {
    handleAccept() {
      this.$store
        .dispatch('jobOffer/accept', {
          id: this.config.data.model.id,
          rams_accepted: this.rams_accepted,
        })
        .then(() => {
          this.$store.dispatch(
            'messenger/fetchMessages',
            this.config.data.conversation_id
          );
        })
        .catch(() => {
          this.$store.dispatch(
            'messenger/fetchMessages',
            this.config.data.conversation_id
          );
        });
    },

    handleDecline() {
      this.$store
        .dispatch('jobOffer/reject', { id: this.config.data.model.id })
        .then(() => {
          this.$store.dispatch(
            'messenger/fetchMessages',
            this.config.data.conversation_id
          );
        });
    },

    matchRole(id) {
      return this.$store.getters['dict/matchRole'](id).value;
    },

    closeOffer() {
      EventBus.$emit('closeDrawer');
    },

    handleSendOffer() {
      const model = this.config.data.model;

      this.closeOffer();

      EventBus.$emit('openDrawer', {
        type: 'create-offer',
        config: this.config.recipient,
        preset: model,
        refresh: true,
      });
    },

    makeReadable(text) {
      // @fixme: move to mixin
      return text.toLowerCase().split('_').join(' ');
    },
  },
  computed: {
    isOffer() {
      return this.config.data.model.model_type === 'offer';
    },

    isPost() {
      return this.config.data.model.model_type === 'post';
    },

    user() {
      return this.$store.getters['auth/getUser'];
    },

    isWorker() {
      return this.user.default_role === 'WORKER';
    },

    isRejected() {
      return this.config.data.model.status === 'REJECTED';
    },

    isAccepted() {
      return this.config.data.model.status === 'ACCEPTED';
    },

    isExpired() {
      return (
        new Date().getTime() >
        new Date(this.config.data.model.due_date).getTime()
      );
    },

    rams() {
      if (this.config.data.model.site && this.config.data.model.site.media) {
        return this.config.data.model.site.media.filter(item => {
          return (
            (item.collection_name === 'rams' &&
              item.custom_properties.roles.indexOf(
                this.config.data.model.role_id
              ) >= 0) ||
            (item.collection_name === 'rams' &&
              item.custom_properties.roles.length === 0)
          );
        });
      }
    },

    errors() {
      let error = this.$store.getters['jobOffer/getError'];
      let msg = [];

      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).forEach(key => {
          msg.push(error.response.data.errors[key]);
        });
      } else if (error.response && error.response.data) {
        msg.push(error.response.data.message);
      }
      return msg.join(', ');
    },
  },
};
</script>

<style lang="scss">
@import '../../assets/scss/global_vars';

.view-offer {
  &-title {
    margin-bottom: 30px;
  }

  &.expired {
    position: relative;

    * {
      color: rgba(gray, 0.95) !important;
    }

    .expired-message {
      opacity: 1;
      color: $dark-medium !important;
      font-weight: $fontBolder;
      z-index: 2;
      line-height: 40px;
      font-size: 40px;
      margin-bottom: 50px;
    }
  }

  .rams {
    display: flex;
    justify-content: flex-start;
    padding-top: 2rem;
    padding-bottom: 60px;
    flex-wrap: wrap;

    &-header {
      margin-top: 60px;

      .ui-checkbox {
        margin-bottom: 0;
      }
    }
  }

  .ram {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-wrapper {
      min-width: 100px;
    }

    &-image {
      margin-bottom: 1rem;
    }

    &-title {
      color: #646464;
      font-size: 13px;
      line-height: 1.38;
    }
  }
}
</style>
