export default {
    setSucces: (state, data) => {
        state.succes = data;
    },

    setHasForm: (state, data) => {
        state.hasForm = data;
    },
    
    setFormData: (state, data) => {
        state.formData = data;
    },

    setVisitors: (state, data) => {
        state.visitors = data;
    },

    setInviteData: (state, data) => {
        state.inviteData = data;
    },

    setVisitor: (state, data) => {
        state.visitor = data;
    },

    setError: (state, data) => {
        state.error = data;
    },

    setPdfQr: (state, data) => {
        state.qrPdf = data;
    },

    create: (state, data) => {
        state.create = data;
    }
}