import { errorSnackbar, successSnackbar } from '~/plugins/event-bus';
import {disableLoader, enableLoader} from "@/helpers/loader";

export default {
  async fetchSiteInfo({ commit }) {
    try {
      const siteInfo = await this.$axios.get('/api/site-control');
      commit('setSiteInfo', siteInfo.data.site);
      return siteInfo;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },

  async fetchUserInfo({ commit }, payload) {
    try {
      commit('setUserInfo', {});
      const user = await this.$axios.get(
        `/api/site-control/workers/${payload.userId}${
          payload.isScan ? '/?source=scan' : ''
        }`
      );
      commit('setUserInfo', user.data);
      return user;
    } catch (err) {
      commit('setUserInfo', {});
      console.log(err);
      throw err;
    }
  },

  async fetchSiteWorkers({ commit }) {
    try {
      const people = await this.$axios.get('/api/site-control/workers');
      commit('setSiteWorkers', people.data.workers);
      commit('setSiteManagables', people.data.managables);
      return people;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },

  async userManualCheckInOrCheckOut({ commit }, payload) {
    enableLoader()
    const checkInOrOut = payload.isOnSite ? 'check-out' : 'check-in';
    try {
      const response = await this.$axios.post(
        `/api/site-control/workers/${payload.id}/${checkInOrOut}${
          payload.isScan ? '/?source=scan' : ''
        }`,
        { worker_password: payload.password }
      );
      disableLoader()
      successSnackbar('Success!');
      return response;
    } catch (err) {
      errorSnackbar(
        err.response.data.errors &&
          err.response.data.errors.worker_password &&
          err.response.data.errors.worker_password.length
          ? err.response.data.errors.worker_password[0]
          : err.response.data.message
      );
      console.log(err);
      disableLoader()
      throw err;
    }
  },
};
