import { EventBus } from '~/plugins/event-bus';
import {disableLoader, enableLoader} from "@/helpers/loader";

export default {
  async init({ dispatch, state, commit }, companyId) {
    if (!state.hasData) {
      dispatch('fetch', companyId);
    }
  },

  async fetch({ commit }) {
    try {
      const company = await this.$axios.get(`/api/me/company`);
      commit('setCompany', company.data.data);
      commit('toggleData');
    } catch (err) {}
  },

  async update({ commit, dispatch }, payload) {
    enableLoader()
    commit('setError', '');
    try {
      let response;
      if (null !== payload.formData.id && '' !== payload.formData.id) {
        commit('setPaymentOption', payload.formData);
        response = await this.$axios.put('/api/me/company', payload.formData);
      } else {
        response = await this.$axios.post('/api/me/company', payload.formData);
      }
      const token = await dispatch('auth/refreshToken', null, { root: true });
      disableLoader()
      this.$router.push(payload.redirectUrl);
    } catch (err) {
      commit('setError', err);
    }
    disableLoader()
  },

  async swap({ commit, dispatch }, payload) {
    enableLoader()
    commit('setError', '');
    try {
      await this.$axios.put('/api/me/company/swap', payload.formData);
    } catch (err) {
      commit('setError', err);
    }
    disableLoader()
  },

  async details({ commit, dispatch }, payload) {
    enableLoader()
    commit('setError', '');
    try {
      const company = await this.$axios.put('/api/me/company', payload);
      commit('setCompany', company.data.data);
      EventBus.$emit('createSnackbar', {
        message: `Company details successfully updated.`,
      });
    } catch (err) {
      commit('setError', err);
      EventBus.$emit('createSnackbar', {
        message: `Error during details update`,
      });
    }
    disableLoader()
  },

  async fetchSubcontractors({ commit }) {
    try {
      const company = await this.$axios.get(`/api/me/subcontractors`);
      commit('setSubcontractors', company.data.data);
    } catch (err) {}
  },
};
