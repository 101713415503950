import Vue                   from 'vue';
import {ReactNativeMessages} from "~/mixins/reactNativeChannel";

export const EventBus = new Vue();


export function errorSnackbar(msg = 'An error occured.') {
  EventBus.$emit('createSnackbar', {message: `${msg}`});
}

export function successSnackbar(msg = 'Saved.') {
  EventBus.$emit('createSnackbar', {message: `${msg}`});
}

export function createSnackbar(msg) {
  EventBus.$emit('createSnackbar', {message: `${msg}`});
}
