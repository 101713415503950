export default () => ({
  mediaRefresh: null,
  mediaMap: {},
  hasData: false,
  error: '',
  uploading: [],
  uploaded: [],
  canceled: false,
  showError: false,
});
