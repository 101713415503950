import { EventBus } from '~/plugins/event-bus';
export default {
  async fetchPage({ commit }, slug) {
    const page = await this.$axios({
      method: 'get',
      baseURL: process.env.WP_URL,
      url: '/wp-json/wp/v2/pages',
      params: { slug },
    });
    try {
      console.log(page);
      if (page.data.length) {
        commit('setPage', page.data[0]);
      } else {
        throw err;
      }
    } catch (err) {
      console.log(err);
    }
  },

  async fetch({commit}) {
    try {
      const response = await this.$axios.get('/api/me/help', {
         responseType: "arraybuffer"
      });
      commit('setPath', response.data);
    } catch (err) {
      EventBus.$emit('createSnackbar', { message: err.message });
    }
  }
};
