export default {
    getSucces: (state) => {
        return state.succes;
    },

    getHasForm: (state) => {
        return state.hasForm;
    },

    getFormData: (state) => {
        return state.formData;
    },

    getVisitors: (state) => {
        return state.visitors;
    },

    getInviteData: (state) => {
        return state.inviteData;
    },

    getVisitor: (state) => {
        return state.visitor;
    },

    getError: (state) => {
        return state.error;
    },

    getQrPdf: (state) => {
        return state.qrPdf;
    },

    getCreate: (state) => {
        return state.create;
    }
}