export default {
  getWorkers: state => {
    return state.workers;
  },
  getLinks: state => {
    return state.links;
  },
  getMeta: state => {
    return state.meta;
  },
  getError: state => {
    return state.error;
  },
  getSimpleWorker: state => {
    return state.simpleWorker;
  },
  getAssignments: state => {
    return state.assignments;
  },
  getContracts: state => {
    return state.contracts;
  },
  getTimesheets: state => {
    return state.timesheets;
  },
  getBatchInfo: state => {
    return state.batchInfo;
  },
  getClear: state => {
    return state.clear
  },
  getCompanies: state => {
    return state.companies;
  },
  getSite: state => {
    return state.site;
  },
  getFilters: state => {
    return state.filters || [];
  },
  getFilter: state => {
    return state.filter
  },
  getNewWorker: state => {
    return state.newWorker;
  }
};
