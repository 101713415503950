import createAuthRefreshInterceptor                from 'axios-auth-refresh';
import {EventBus}                                  from '~/plugins/event-bus';
import {
  getMobileWorkerRegisterFlowPath,
  isDebug, isInWebView, isLocalhost,
  isMobileWorkerRegisterFlow,
  redirectToMobileWorkerRegisterFlow
}                                                  from "@/helpers/environment";
import LocalStorage, {DEVICE_ID_LOCAL_STORAGE_KEY} from "@/helpers/local-storage";

const is401 = (response) => {
  return response && parseInt(response.status) === 401

}
const isDeviceIdMiss = (response) => {
  return response && is401(response) && response.headers && (response.headers['x-device-id-miss']);
}
const isPhoneNumberMiss = (response) => {
  return response && is401(response) && response.headers && (response.headers['x-phone-number-miss']);
}
const isApiRateLimited = (response) => {
  return response && parseInt(response.status) === 429;
}
// const guardToken = localStorage.getItem('guard-token');

export default ({$axios, redirect, store, route}) => {
  $axios.onRequest(config => {
    const token = LocalStorage.getToken();

    config.headers.common['Authorization'] = 'Bearer ' + token; // todo investigate is common object needed for backend auth somewhere
    config.headers['Authorization'] = 'Bearer ' + token;

    if (isInWebView()) {
      config.headers.common['X-Web-View'] = 1;

      /** Assign deviceId to phoneNumber only if successfully authenticated **/
      if (token) {
        const deviceId = window.localStorage.getItem(DEVICE_ID_LOCAL_STORAGE_KEY);
        config.headers.common['X-Device-Id'] = deviceId;
      }
    }
    if (config.headers.common.hasOwnProperty('Authorization')) {
      if (
        isDebug() ||
        localStorage.getItem('log') === 'true'
      ) {
        console.log(
          `%c ${config.method.toUpperCase()} request to ${
            config.url
          } // ${config.headers.common.Authorization.slice(-5)}`,
          'color: #bada55; font-weight: bold'
        );
      }
    }
    localStorage.removeItem('guard-token');
  });

  $axios.onError(error => {
    const code = parseInt(error.response && error.response.status);
    const originalRequest = error.response.config;

    if (code === 400) {
      redirect('/');
      EventBus.$emit('createSnackbar', {
        message: `An error has occured. Requested action could not be finished`,
      });
    }
    if (code === 429) {
      if (!isLocalhost()) {
        EventBus.$emit('createSnackbar', {
          // todo: notify sentry
          message: `hey! not so fast. our system detected unusual number of requests from your device. if you believe it is an error please contact our customer support`,
        });
      }
    }
    if (isDeviceIdMiss(error.response) || isPhoneNumberMiss(error.response)) {

      // redirect('/');
      EventBus.$emit('createSnackbar', {
        message: `Sorry that number is already taken by another device`,
      });

    }

    if (code === 500) {
      console.log('Server error: ', {error});
      EventBus.$emit('createSnackbar', {
        message: `Server error has occured.`,
      });
    }
  });

  // Function that will be called to refresh authorization
  const refreshAuthLogic = err => {
    if (
      err.response &&
      err.response.data &&
      err.response.data.from &&
      'login' === err.response.data.from
    ) {
      return Promise.reject(err);
    }

    if (isDeviceIdMiss(err.response) || isPhoneNumberMiss(err.response)) {
      return Promise.reject(err);
    }
    if (isApiRateLimited(err.response)) {
      return Promise.reject(err);
    }

    return $axios
      .post('/api/auth/refresh')
      .then(res => {
        LocalStorage.setToken(res.data.tokenInfo.accessToken)
        err.response.config.headers['Authorization'] =
          'Bearer ' + LocalStorage.getToken();

        return Promise.resolve(res);
      })
      .catch(error => {
        store.commit('auth/setError', error);
        LocalStorage.removeToken();
        return Promise.reject(error);
      });
  };

  // Instantiate the interceptor (you can chain it as it returns the axios instance)
  createAuthRefreshInterceptor($axios, refreshAuthLogic);
};
