export default {
  getAll: state => {
    return state.notifications;
  },

  getNew: state => {
    return state.notifications.filter(n => {
      return !n.read_at;
    });
  },

  getError: state => {
    return state.error;
  },
};
