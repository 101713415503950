import moment from 'moment-timezone';
const timezone = process.env.TIMEZONE;

export default {
  methods: {
    moment() {
      // DEPRECATED
      // this function is handling unix timestamp INCORRECTLY
      return moment();
    },

    getTime(time) {
      if ('string' === typeof time) {
        time = time.split(':');
        if (time.length < 2) {
          return '-';
        }

        return time[0] + ':' + time[1];
      }
    },

    getDate(date = new Date(), format = 'DD/MM/YYYY', unix = true) {
      if (unix) {
        return moment.unix(date).tz(timezone).format(format);
      } else {
        // const newDate = new Date(date);
        // return moment(newDate).tz(timezone).format(format);
        return moment(date).tz(timezone).format(format);
      }
    },


    formatDate (date, format = 'DD/MM/YYYY') {
      // works better at ios
      return moment(date).tz(timezone).format(format);
    },

    toMoment(date = new Date()) {
      return moment(date);
    }
  },
};
