export default {
  setCompany: (state, company) => {
    state.company = company;
  },
  setPaymentOption: (state, option) => {
    state.option = option;
  },
  toggleData: state => {
    state.hasData = true;
  },
  setError: (state, err) => {
    state.error = err;
  },
  setSubcontractors: (state, subcontractors) => {
    let data = subcontractors.map(sub => {
      return {
        id: sub.id,
        name: sub.name + ' (' + sub.site.name + ')',
      };
    });

    state.subcontractors = data;
  },
};
