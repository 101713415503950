export default {
  setSiteInfo: (state, info) => {
    state.siteInfo = info || {};
  },
  setSiteManagables: (state, managables) => {
    state.managables = managables || [];
  },
  setSiteWorkers: (state, workers) => {
    state.workers = workers || [];
  },
  setUserInfo: (state, user) => {
      state.userInfo = user || {};
  }
};
