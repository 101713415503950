<template>
  <div class="register-template">
    <div class="register-wrapper">
      <nuxt />
    </div>
    <LoaderComponent />
  </div>
</template>

<script>
import LoaderComponent from '../components/LoaderComponent.vue';

export default {
  components: {
    LoaderComponent,
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/global_vars';

.register-template {
  .register-wrapper {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
  }
  .register-form {
    margin: 2rem 0;
    width: 630px;
    transition: all 0.2s ease-out;

    @media screen and (max-width: 1400px) {
      margin-left: 275px;
    }

    @media screen and (max-width: 1100px) {
      margin-left: 0;
      margin-top: 300px;
      margin-bottom: 100px;
    }

    @media screen and (max-width: 768px) {
      margin-top: 100px;
    }
  }

  .registration__step {
    margin-bottom: 60px;
  }

  .form-section {
    margin-bottom: 50px;
  }

  h3 {
    color: $dark-main;
    font-size: 22px;
    margin-bottom: 0;
  }
}
</style>
