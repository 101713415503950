// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/worker-registration/registration-wizard-bg.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".registration-wizard-wrap{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:30% 110%;background-repeat:no-repeat;background-size:130% auto;display:flex;flex-direction:column;margin-bottom:40vh;min-height:100vh;position:relative}.registration-wizard-wrap .go-back{padding:10px 30px}.registration-wizard-wrap .cta-wrap{padding:0 52px}.registration-wizard-wrap .filler{flex-grow:1}.registration-wizard-wrap .footer{padding-bottom:52px}.registration-wizard-wrap .step-content{display:flex;flex-direction:column;flex-grow:1;padding:52px 0}.registration-wizard-wrap .header h1{margin-bottom:16px}.registration-wizard-wrap .header{color:#303030}.registration-wizard-wrap .registration-wizard__super-title{font-size:45px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
