/**
 * Quick object check - this is primarily used to tell
 * Objects from primitive values when we know the value
 * is a JSON-compliant type.
 */
export function isObject(obj) {
  return obj !== null && typeof obj === 'object';
}

/**
 * Check if two values are loosely equal - that is,
 * if they are plain objects, do they have the same shape?
 */
export function looseEqual(a, b, id_key) {
  // eslint-disable-next-line eqeqeq
  return (
    a == b ||
    (isObject(a) && isObject(b)
      ? (typeof id_key != 'undefined' && a[id_key] === b[id_key]) || JSON.stringify(a) === JSON.stringify(b)
      : false)
  );
}

/**
 * Check if a val exists in arr using looseEqual comparison
 */
export function looseIndexOf(arr, val, id_key) {
  if (arr) {
    for (let i = 0; i < arr.length; i++) {
      if (looseEqual(arr[i], val, id_key)) {
        return i;
      }
    }

    return -1;
  }
}

/**
 * Check if the given string starts with the query, beginning
 * at the given position
 */
export function startsWith(string, query, position = 0) {
  return string.substr(position, query.length) === query;
}
