import {isMobileWorkerRegisterFlow, redirectToMobileWorkerRegisterFlow} from "@/helpers/environment";
import {EventBus}                                                       from "@/plugins/event-bus";
import {ReactNativeMessages}                                            from "@/mixins/reactNativeChannel";
import debuglog                                                         from "@/helpers/debuglog";

export const ACCESS_TOKEN_LOCAL_STORAGE_KEY = "sitecircle_access_token";
export const DEVICE_ID_LOCAL_STORAGE_KEY = "sitecircle_device_id";

const LocalStorage = {
  setToken(token, blockNotify = false) {
    let oldToken = LocalStorage.getToken();
    window.localStorage.setItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, token);
    if (!blockNotify && oldToken !== token) {
      this.forceNotifyNewToken();
    }
  },
  /**
   * u can subscribe to new token events
   */
  forceNotifyNewToken() {
    let token = this.getToken();
    debuglog("Notifying new token", token);
    EventBus.$emit('auth-new-token-received', {token: token});
  },
  getToken() {
    return window.localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
  },
  /**
   * removes token and redirects to login page
   */
  removeToken() {
    window.localStorage.removeItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
    if (isMobileWorkerRegisterFlow({path: window.location.pathname})) {
      redirectToMobileWorkerRegisterFlow();
    } else {
      // redirect('/login');
      //force page reload to clear application state
      window.location.href = window.location.origin + '/login';
    }
  },
  /**
   * Sugar for running action on new token saved to local storage
   * @param eventName
   * @param callback
   */
  listen(eventName, callback) {
    switch (eventName) {
      case 'new-token':
        EventBus.$on('auth-new-token-received', ({token}) => {
          callback({token});
        });
        break;
    }
  }
};
export default LocalStorage;
