export default {
  async init({ dispatch, state }) {
    if (!state.hasData) {
      dispatch('fetch');
    }
  },

  async fetch({ commit }) {
    try {
      const getDict = this.$axios.get('/api/dict')
      const getLoc = this.$axios.get('/api/locations')
      const dict = await getDict
      commit('setDict', dict.data.data);
      const locs = await getLoc
      commit('setLocations', locs.data.data);

      commit('toggleData');
    } catch (err) {}
  },
};
