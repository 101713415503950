export default {
  setTimesheets: (state, timesheets) => {
    state.timesheets = timesheets;
  },

  setTimesheet: (state, timesheet) => {
    state.timesheet = timesheet;
  },

  setPendingTimesheets: (state, isPending = true) => {
    state.isTimesheetsPending = isPending;
  },

  setTimesheetMessage: (state, msgs) => {
    state.timesheet.messages.data = msgs;
  },

  toggleData: state => {
    state.hasData = true;
  },

  setFilters: (state, filters) => {
    Object.keys(filters).forEach(key => {
      let resetItem = { id: null, name: 'All' };
      if (key === 'statuses') {
        return;
      }

      if (filters[key].hasOwnProperty('length')) {
        filters[key] = [resetItem, ...filters[key]];
      }
    });

    state.filters = filters;
  },

  setError: (state, error) => {
    state.error = error;
  },

  setExcelUrl: (state, url) => {
    state.excelUrl = url;
  },
};
