export default {
  getConversations: state => {
    return state.conversations;
  },
  getMessages: state => {
    return state.messages;
  },
  getTotal: state => {
    return state.total;
  },
  getUnseen: state => {
    return state.unseenMessages;
  },
  getActive: state => {
    return state.activeConversation;
  },
};
