import { render, staticRenderFns } from "./LoaderComponent.vue?vue&type=template&id=f0732838&scoped=true"
import script from "./LoaderComponent.vue?vue&type=script&lang=js"
export * from "./LoaderComponent.vue?vue&type=script&lang=js"
import style0 from "./LoaderComponent.vue?vue&type=style&index=0&id=f0732838&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0732838",
  null
  
)

export default component.exports