export default () => ({
  cards: null,
  workers: null,
  card: null,
  links: null,
  meta: null,
  hasData: false,
  error: null,
  selectedCard:null,
  selectedUserId:null,
  step:'selectCard'
});
