<template>
    <div class="dropdown">
        <button class="dropdown-button btn-transparent" @click="show">
            <i class="icon more"></i>
        </button>
        <div class="dropdown-list" v-if="isActive" >
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        isActive: {
            type: Boolean
        },
        show: {
            type: Function
        }
    }
}
</script>
<style lang="scss" scoped>
    .dropdown {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    .dropdown-button {
        cursor: pointer;
        padding: 0 10px;
    }
    .dropdown-list {
        background-color: #fff;
        border: 1px solid #e4e4e4;
        border-radius: 8px;
        cursor: pointer;
        span {
            display: inline-block;
            padding: 10px;
        }
        &:hover {
            box-shadow: 0 10px 34px 0 rgba(210, 210 ,210, 0.5);
        }
    }
</style>