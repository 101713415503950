export default {
  getAll: state => {
    return {
      works: state.works,
      links: state.links,
      meta: state.meta,
    };
  },
  getWorks: state => {
    return state.works ? state.works : [];
  },
  getWork: state => {
    return state.work;
  },
  getLinks: state => {
    return state.links;
  },
  getMeta: state => {
    return state.meta;
  },
};
