export default {
  hasResentCode(state, hasResentCode) {
    state.hasResentCode = hasResentCode;
  },
  verificationCode(state, verificationCode) {
    state.verificationCode = verificationCode;
  },
  phoneNumber: (state, phoneNumber) => state.phoneNumber = phoneNumber,
  setCurrentStep(state, stepId) {
    state.currentStepId = stepId;// WRS_PHONE_VERIFICATION
  },
  setCurrentSubstep(state, substepId) {
    state.currentSubstepId = substepId;
  },
  setError: (state, err) => {
    state.error = err;
  },
  applicantId: (state, applicantId) => state.applicantId = applicantId,
  checkId: (state, checkId) => state.checkId = checkId,
  checkStatus: (state, checkStatus) => state.checkStatus = checkStatus,
  sdkToken: (state, sdkToken) => state.sdkToken = sdkToken,
};
