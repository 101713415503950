export default {
  getAll: state => {
    return {
      cards: state.cards,
      links: state.links,
      meta: state.meta,
    };
  },
  getCards: state => {
    return state.cards;
  },
  getWorkers: state => {
    return state.workers;
  },
  getCard: state => {
    return state.card;
  },
  getSelectedCard: state => {
    return state.selectedCard;
  },
  getError: state => {
    return state.error;
  },
  getMeta: state => {
    return state.meta;
  },
  getStep: state => {
    return state.step;
  },
};
