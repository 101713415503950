<template>
  <span class="info-popup">
    <img class="info-popup__icon" src="~/assets/images/info.svg" alt="i" @click="clickIt"/>
    <UiTooltip position="top">
      <slot></slot>
    </UiTooltip>
  </span>
</template>

<script>
export default {
  name: 'InfoPopup',

  methods: {
    clickIt() {
      this.$emit('click')
    }
  }
};
</script>

<style lang="scss">
@import '../assets/scss/global_vars';

.info-popup {
  &__icon {
    max-width: 16px;
  }
}
</style>
