export default {
  getAll: state => {
    return {
      contracts: state.contracts,
      links: state.links,
      meta: state.meta,
    };
  },
  getContracts: state => {
    return state.contracts;
  },
  getContract: state => {
    return state.contract;
  },
  getError: state => {
    return state.error;
  },
  getMeta: state => {
    return state.meta;
  },
};
