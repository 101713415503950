<template>
  <div v-if="count" class="notifications__list">
    <button @click="markAllRead" class="notifications__mark-read underline">
      Mark all as read
    </button>

    <NotificationsItem
      v-for="notification in notifications"
      :notification="notification"
      :key="notification.id"
    />
  </div>

  <div v-else>
    No notifications.
  </div>
</template>

<script>
import NotificationsItem from '~/components/notifications/NotificationsItem';

export default {
  name: 'NotificationsList',
  components: {
    NotificationsItem,
  },
  mounted() {
    this.$store.dispatch('notifications/fetch');
  },
  computed: {
    notifications() {
      return this.$store.getters['notifications/getAll'];
    },

    count() {
      if (this.notifications) {
        return this.notifications.length;
      }
      return false;
    },
  },
  methods: {
    markAllRead() {
      this.$store.dispatch('notifications/markRead');
    },
  },
};
</script>

<style lang="scss">
.notifications {
  &__list {
    height: 490px;
    overflow-x: hidden;
    overflow-y: auto;
    margin: -30px;
  }

  &__mark-read {
    margin-left: 40px;
    margin-top: 35px;
    margin-bottom: 12px;
    font-size: 14px;
    color: #424242;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }
}
</style>
