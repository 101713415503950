<template>
  <div>
    <LoginForm />
  </div>
</template>

<script>
import LoginForm from '@/components/LoginForm';
export default {
  layout: 'register',
  components: { LoginForm },
};
</script>
