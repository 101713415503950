export default {
  setCards: (state, cards) => {
    state.cards = cards;
  },
  setWorkers: (state, workers) => {
    state.workers = workers;
  },
  setStep: (state, step) => {
    state.step = step;
  },

  setLinks: (state, links) => {
    state.links = links;
  },

  setMeta: (state, meta) => {
    state.meta = meta;
  },

  toggleData: state => {
    state.hasData = true;
  },

  setError: (state, error) => {
    state.error = error;
  },
  setSelectedCard: (state, selectedCard) => {
    state.selectedCard = selectedCard
  },
  setSelectedUserId: (state, selectedUserId) => {
    state.selectedUserId = selectedUserId
  }
};
