\<template>
  <div class="notifications-item" @click="handleClick" :class="{ new: isNew }">
    <p class="notifications-item__description mb-2">
      {{ description }}
    </p>
    <p class="notifications-item__date mb-0">
      {{ format(meta.created_at) }}
    </p>
  </div>
</template>

<script>
import moment from 'moment';
import notificationActions from '~/mixins/notificationActions';

export default {
  name: 'NotificationsItem',
  props: ['notification'],
  mixins: [notificationActions],
  methods: {
    format(date) {
      if (date) {
        return moment(date).format("DD/MM/YYYY");
      }
    },

    typeAction(type) {
      return type
        .toLowerCase()
        .split(':')
        .map((type, index) => {
          return index > 0
            ? type.charAt(0).toUpperCase() + type.slice(1)
            : type;
        })
        .join('');
    },

    handleClick() {
      const action = this.action;
      const type = this.meta.type;
      const typeAction = this.typeAction(type);
      const isNew = this.isNew;

      try {
        const route = this[typeAction](action);
        if (route && route !== this.$route.fullpath) {
          this.$router.push(route);
        }
      } catch (err) {
        console.log(
          '%c Error: ' + typeAction + ' ' + err.message,
          'color: indianred; font-weight: 600'
        );
      }

      if (isNew) {
        this.$store.dispatch('notifications/markRead', this.notification.id);
      }
    },
  },
  computed: {
    isNew() {
      return !this.notification['read_at'];
    },

    action() {
      if (this.notification.hasOwnProperty('data')) {
        return this.notification.data.action;
      }
      return this.notification.action;
    },

    meta() {
      if (this.notification.hasOwnProperty('data')) {
        return this.notification.data.meta;
      }
      return this.notification.meta;
    },

    description() {
      if (this.notification.hasOwnProperty('data')) {
        return this.notification.data.description;
      }
      return this.notification.description;
    },
  },
};
</script>

<style lang="scss">
@import '../../assets/scss/global_vars';

.notifications-item {
  border-bottom: 1px solid #f4f4f5;
  padding: 20px 40px;
  width: 390px;
  cursor: pointer;
  transition: background-color 0.1s;
  position: relative;

  &.new {
    &:before {
      $size: 10px;
      content: '';
      background-color: #ff3333;
      display: block;
      width: $size;
      height: $size;
      position: absolute;
      border-radius: 50%;
      top: 27px;
      left: 20px;
    }
  }

  &:hover {
    background-color: $light-gray;
  }

  &__description {
    color: #424242;
    min-height: 38px;
  }

  &__date {
    font-size: 12px;
    line-height: 1.33;
    color: #9ea5b1;
  }
}
</style>
