<template>
  <section class="drawer" @click.self="close" v-if="showDrawer">
    <transition name="slide">
      <div class="drawer-container" :class="`drawer-${this.type}`">
        <div v-if="typeCreateOffer">
          <CreateJobOffer :config="config" :preset="preset" />
        </div>

        <div v-if="typeViewOffer">
          <ViewJobOffer :config="config" />
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
import CreateJobOffer from '@/components/messages/CreateJobOfferForm';
import ViewJobOffer from '@/components/messages/ViewJobOfferComponent';
import { EventBus } from '~/plugins/event-bus';

export default {
  components: { CreateJobOffer, ViewJobOffer },
  data() {
    return {
      showDrawer: false,
      type: null,
      config: null,
      preset: null,
    };
  },
  mounted() {
    EventBus.$on('openDrawer', payload => {
      if (payload.hasOwnProperty('type')) {
        this.type = payload.type;
      }

      if (payload.hasOwnProperty('config')) {
        this.config = payload.config;
      }

      if (payload.hasOwnProperty('preset')) {
        this.preset = payload.preset;
      }

      this.open();
    });

    EventBus.$on('closeDrawer', () => {
      this.close();
    });
  },
  computed: {
    typeCreateOffer() {
      return this.type === 'create-offer';
    },
    typeViewOffer() {
      return this.type === 'view-offer';
    },
  },
  methods: {
    open() {
      this.showDrawer = true;
    },
    close() {
      this.showDrawer = false;
      this.type = false;
      this.config = false;
    },
  },
};
</script>

<style lang="scss">
@import '../assets/scss/global_vars';
.drawer {
  position: absolute;
  z-index: 2;
  width: calc(100vw - 1px);
  top: 100px;
  right: 0;
  left: 0;
  bottom: 0;
  animation: bgAnimation 0.3s ease-out both;
  transform: translate3d(1px, 1px, 0);

  @keyframes bgAnimation {
    0% {
      background-color: rgba(black, 0);
    }
    100% {
      background-color: rgba(black, 0.25);
    }
  }

  &-container {
    float: right;
    width: 300px;
    height: 100%;
    padding: 60px;
    z-index: 4;
    background-color: $white;
    animation: barAnimation 0.3s ease-out both;
    overflow-y: auto;
  }

  &-create-offer {
    width: 642px;
    @media (max-width: $mobileWidth) {
      width: 100%;
    }
  }

  &-view-offer {
    width: 642px;
    @media (max-width: $mobileWidth) {
      width: 100%;
    }
  }

  @keyframes barAnimation {
    0% {
      right: -100%;
    }
    100% {
      right: 0;
    }
  }
}
</style>
