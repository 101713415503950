<template>
  <div class="fake-input">
    <p class="label">
      {{ label }}
    </p>
    <p class="value">
      {{ value }}
      <slot></slot>
    </p>
  </div>
</template>

<script>
export default {
  name: 'FakeInput',
  props: ['type', 'value', 'label'],
};
</script>

<style lang="scss" scoped>
.fake-input {
  display: flex;
  flex-direction: column;
  min-height: 55px;
  .label {
    font-size: 10px;
    color: #646464;
    line-height: 12px;
    margin-bottom: 0;
  }

  .value {
    font-size: 16px;
    color: #424242;
    min-height: 40px;
  }
}
</style>
