export default {
  getCompany: state => {
    return state.company;
  },
  getError: state => {
    return state.error;
  },
  getOption: state => {
    return state.option;
  },
  getSubcontractors: state => {
    return state.subcontractors;
  },
};
