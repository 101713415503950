import {disableLoader, enableLoader} from "@/helpers/loader";

export default {
  async fetchPlans({ commit, state }) {
    try {
      const plans = await this.$axios.get(`/api/plans`);
      commit('setPlans', plans.data.data);
    } catch (err) {}
  },

  async fetchInvoices({ commit, state }, id) {
    try {
      const response = await this.$axios.get(`/api/me/company/invoices`);
      commit('setInvoices', response.data.data);
    } catch (err) {
      console.log('ERROR FETCHING INVOICES', { err });
    }
  },

  async fetchBilling({ commit, state }, id) {
    try {
      const response = await this.$axios.get(`/api/me/company/billing`);
      commit('setBilling', response.data.data);
    } catch (err) {
      console.log('EORROR FETCHING BILLINGS', { err });
    }
  },

  async update({ commit, state }, payload) {
    enableLoader()
    try {
      const response = await this.$axios.put(`/api/me/company/billing`, {
        stripe_token: payload.stripe_token,
      });
      commit('setBilling', response.data.data);
    } catch (err) {
      console.log('EORROR FETCHING BILLINGS', { err });
    }
    disableLoader()
  },
};
