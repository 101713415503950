export default () => ({
  sites: [],
  site: null,
  siteStats: [],
  workers: {},
  workersMeta: {},
  checkins: {},
  checkinsMeta: {},
  hasData: false,
  error: '',
  firePrintUrl: null,
  subcontractors: [],
  subcontractor: null,
  gates: [],
  meta: {},
  checkInExcelUrl: null,
  subcompanyRequests: [],
  workerDocAuditRecs: [],
  workerDocAuditRecsMeta: {},
});
