import { EventBus, errorSnackbar, successSnackbar } from '~/plugins/event-bus';
import {disableLoader, enableLoader} from "@/helpers/loader";

export default {
  async updateGeneral({ commit, dispatch }, payload) {
    enableLoader()
    commit('setError', '');
    try {
      await this.$axios.put('/api/me', payload);
      commit('auth/updateUserAccount', payload, { root: true });
      successSnackbar();
    } catch (err) {
      commit('setError', err);
      errorSnackbar(err.response.data.message);
    }
    disableLoader()
  },

  async fetchAgreements({ commit }) {
    try {
      // TODO: check endpoint and payload
      const agreements = await this.$axios.$get('/api/me/messages');
      // http://localhost:3000/settings/account

      commit('setAgreements', agreements.data);
    } catch (err) {}
  },

  async updateAgreements({ commit, dispatch }, payload) {
    enableLoader()
    commit('setError', '');
    try {
      const response = await this.$axios.put('/api/me/messages', payload);
      commit('setAgreements', response.data);
      successSnackbar();
    } catch (err) {
      commit('setError', err);
      errorSnackbar();
    }
    disableLoader()
  },

  async updatePassword({ commit, dispatch }, passwords) {
    enableLoader()
    commit('setError', '');
    try {
      const response = await this.$axios.put('/api/me/password', passwords);
      successSnackbar('Password setting changed successfully.');
    } catch (err) {
      commit('setError', err);
      errorSnackbar();
    }
    disableLoader()
  },
};
