import LocalStorage from "@/helpers/local-storage";

export default {
  setUser: (state, userData) => {
    if (null === userData) {
      state.user = null;
      return;
    }
    let user = userData;

    // user.has_verified_email = !!user.has_verified_email;
    user.dictMap = {};

    user.dictMapIds = {};

    user.dictionary.forEach(dict => {
      if (!user.dictMap.hasOwnProperty(dict.type)) {
        user.dictMap[dict.type] = [];
        user.dictMapIds[dict.type] = [];
      }

      user.dictMap[dict.type].push(dict.value);
      user.dictMapIds[dict.type].push(dict.id);
    });

    if (user && user.profile && user.profile) {
      user.profile.cardsInfo = [];
    }

    if (user && user.profile && user.profile.cards) {
      Object.values(JSON.parse(user.profile.cards || '[]')).forEach(card => {
        user.profile.cardsInfo.push(card.type);
      });
    }

    user.documents_names = [];

    if (user.documents) {
      (user.documents || []).forEach(doc => {
        user.documents_names.push(doc.custom_properties.name);
      });
    }

    state.user = user;
  },

  mapUser: (state, usr) => {
    console.debug("[AUTH] Got new user",usr);
    state.user = {
      first_name: usr.fna,
      last_name: usr.lna,
      id: usr.uid,
      default_role: usr.dsg,
      status: usr.sts,
      company: usr.cmp,
      // has_verified_email: usr.hve,
      active: usr.act,
      invite_hash: usr.hsh
    };
  },

  updateUserAccount: (state, updated) => {
    Object.keys(updated).forEach(key => {
      if (key !== 'password') {
        state.user[key] = updated[key];
      }
    });
  },

  setToken: (state, token) => {
    LocalStorage.setToken(token);
    state.token = token;
  },

  setVerify: (state, verify) => {
    state.verify = verify;
  },

  setError: (state, err) => {
    state.error = err;
  },

  setInfo: (state, info) => {
    state.info = info;
  },
};
