/** https://dev.to/alligatore3/nuxt-js-dynamic-layouts-by-current-route-3jl1 **/
import {isMobileWorkerRegisterFlow} from "@/helpers/environment";

export default context => {
  if (isMobileWorkerRegisterFlow(context.route)) {
    // For worker-register flow in webview display different error layout
    return 'blank';
  } else {
    return undefined
  }

}
