export default () => ({
  assignments: null,
  assignment: null,
  links: null,
  meta: null,
  hasData: false,
  error: null,
  gettingToWorkTypes: [
    {id: 0, name: 'All'},
    {id: 1, name: 'Walk', image: require('~/assets/icons/person-walking.svg')},
    {id: 2, name: 'Cycle', image: require('~/assets/icons/bicycle.svg')},
    {id: 3, name: 'Bus', image: require('~/assets/icons/bus-front.svg')},
    {id: 4, name: 'Tube', image: require('~/assets/icons/train-front.svg')},
    {id: 5, name: 'Train', image: require('~/assets/icons/train-front.svg')},
    {id: 6, name: 'Car', image: require('~/assets/icons/car-front.svg')},
    {id: 7, name: 'Car share', image: require('~/assets/icons/people-fill.svg')},
  ],
});
