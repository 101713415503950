export default {
  setWorks: (state, works) => {
    state.works = works;
  },
  addWorks: (state, works) => {
    state.works = state.works.concat(works);
  },
  setLinks: (state, links) => {
    state.links = links;
  },
  setMeta: (state, meta) => {
    state.meta = meta;
  },
  setWork: (state, work) => {
    state.work = work;
  },
  toggleData: state => {
    state.hasData = true;
  },
};
