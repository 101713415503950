
export default {
  async init({ dispatch, state, commit }) {
    if (!state.hasData) {
      dispatch('fetch');
    }
  },

  async fetch({ commit }, payload) {
    try {
      const response = await this.$axios.get('/api/workers', {
        params: payload.query,
      });
      const { data, links, meta } = response.data;
      if (payload.reset) {
        commit('setWorkers', data);
      } else {
        commit('addWorkers', data);
      }
      commit('setLinks', links);
      commit('setMeta', meta);
      commit('toggleData');
    } catch (err) {}
  },

  async fetchDetails({ commit }, slug) {
    try {
      const response = await this.$axios.get(`/api/workers/${slug}`);
      const { data, links, meta } = response.data;
      commit('setWorker', data);
      commit('toggleData');
    } catch (err) {}
  },
};
