import {EventBus} from '~/plugins/event-bus';
import jwt from "jsonwebtoken";
import {disableLoader, enableLoader} from "@/helpers/loader";

export default {
  async fetchInvitation({commit}, hash) {
    commit('setInvitation', null)
    try {
      const invitation = await this.$axios.get(`/api/subcompany/invite/${hash}`)
      commit('setInvitation', invitation.data.data)

    } catch (err) {
      EventBus.$emit('createSnackbar', {
        message: `Failed to fetch Invitation`,
      });
    }
  },

  async registerSubcompany({commit}, payload) {
    enableLoader()
    commit('setError', '');
    try {
      const registration = await this.$axios.post(`/api/subcompany/register`, payload.formData)

      const decoded = jwt.decode(registration.data.tokenInfo.accessToken)
      commit('auth/mapUser', decoded.usr, {root: true})
      commit('auth/setToken', registration.data.tokenInfo.accessToken, {root: true})

      this.$router.push(payload.redirectUrl);

    } catch (err) {
      commit('setError', err);

      EventBus.$emit('createSnackbar', {
        message: `Failed to register company`,
      });
    }

    disableLoader()
  },

  async subcompanyDetails({commit}, payload) {
    enableLoader()
    commit('setError', '');
    try {
      const registration = await this.$axios.put(`/api/subcompany/update`, payload.formData)
      this.$router.push(payload.redirectUrl);

    } catch (err) {
      commit('setError', err);

      EventBus.$emit('createSnackbar', {
        message: `Failed to register company`,
      });
    }

    disableLoader()
  },

  async accessRequest({commit}, hash) {
    enableLoader()
    try {
      await this.$axios.put(`/api/subcompany/invite/${hash}`)
    } catch (err) {
      EventBus.$emit('createSnackbar', {
        message: `Failed to register company`,
      });
    }
    disableLoader()
  },

  async invitationStatus({commit}, payload) {
    enableLoader()
    try {
      const response = await this.$axios.post(
        `/api/subcompany/invite-status/${payload.hash}?signature=${payload.signature}&expires=${payload.expires}`,
        {accept: payload.accept})
      disableLoader()
      return response.data
    } catch (err) {
      commit('setError', err);
      EventBus.$emit('createSnackbar', {
        message: `Failed to ${payload.accept ? 'accept' : 'reject'} invitiation.`,
      });
      disableLoader()
    }
  }
}

