<template>
  <div class="logo-wrap">
    <div class="left_slot">
      <slot name="left_slot">
      </slot>
    </div>
    <div class="logo_slot">
      <img alt="SiteCircle" src="~/assets/images/logo-login.svg" class="logo">
    </div>
    <div class="right_slot">
      <DebugView>
        <a href="#" class="blue-link" @click="_debugResetFlow()">reset</a>
      </DebugView>
    </div>
  </div>
</template>
<script>


export default {
  name: 'WorkerRegistrationTopNav',
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/worker_registration_vars';

.logo-wrap {
  height: $topBarHeightMobile;
  display: flex;
  align-items: center;
  justify-content: center;

  position: sticky;
  top: 0;
  left: 0;
  right: 0;

  background-color: #fbfbfd;

  & > * {
    width: 100%;
    display: flex;
  }

  .left_slot {
    flex-shrink: 1;
    justify-content: flex-start;
  }

  .right_slot,
  {
    flex-shrink: 1;
    justify-content: flex-end;
    padding-right: $container-padding;
  }

  .logo_slot {
    flex-grow: 1;
    justify-content: center;
  }
}

.logo {
  max-width: 100%;
}
</style>
