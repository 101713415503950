export default {
  setJobs: (state, jobs) => {
    state.jobs = jobs;
  },
  setLinks: (state, links) => {
    state.links = links;
  },
  setMeta: (state, meta) => {
    state.meta = meta;
  },
  setJob: (state, job) => {
    state.job = job;
  },
  toggleData: state => {
    state.hasData = true;
  },
  setError: (state, err) => {
    state.error = err;
  },
};
