import {errorSnackbar, EventBus, successSnackbar} from '~/plugins/event-bus';
import {disableLoader, enableLoader} from "@/helpers/loader";

export default {
  async init({ dispatch }) {
    dispatch('fetch');
  },

  async fetch({ commit }) {
    try {
      const managers = await this.$axios.get(`/api/me/managers`);
      if (managers.data.data.length) {
        commit('setManagers', managers.data.data);
      }
      commit('toggleData');
    } catch (err) {
      commit('setError', err);
    }
  },

  async fetchDetails({ commit }, slug) {
    try {
      const response = await this.$axios.get(`/api/me/managers/${slug}`);
      const { data, links, meta } = response.data;
      commit('setManager', data);
      commit('toggleData');
    } catch (err) {}
  },

  async add({ commit, dispatch }, newManager) {
    enableLoader()
    commit('setError', null);
    try {
      await this.$axios.post(`/api/me/managers`, newManager);
      dispatch('fetch');
      disableLoader()
      commit('setError', null);
      successSnackbar(`New manager registered with email: ${newManager.email}`)
    } catch (err) {
      disableLoader()
      commit('setError', err);
      errorSnackbar('Error during new manager registration')
      throw new Error(err)
    }
  },

  async remove({ commit, dispatch }, manager) {
    enableLoader()
    commit('setError', null);
    try {
      const res = await this.$axios.delete(`/api/me/managers/` + manager.slug);
      dispatch('fetch');
      successSnackbar(`Manager ${manager.first_name} ${manager.last_name} has been removed.`)
    } catch (err) {
      commit('setError', err);
      errorSnackbar(`Error during manager removal`)
    }
    disableLoader()
  },
  async detach({ commit, dispatch }, payload) {
    enableLoader()
    commit('setError', null);
    try {
      const res = await this.$axios.post(`/api/me/managers/` + payload.manager.slug + `/detach`,{site_id: payload.siteId});
      dispatch('fetch');
      successSnackbar(`Manager ${payload.manager.first_name} ${payload.manager.last_name} has been removed.`)
    } catch (err) {
      commit('setError', err);
      errorSnackbar(`Error during manager removal`)
    }
    disableLoader()
  },

  async update({ commit, dispatch }, payload) {
    enableLoader()
    commit('setError', '');
    try {
      const response = await this.$axios.put(
        '/api/me/managers/' + payload.slug,
        payload.formData
      );

      commit('setManager', response.data.data)

    } catch (err) {
      commit('setError', err);
    }
    disableLoader()
  },

  async updateSubscribes({ commit, dispatch }, payload){
    commit('setError', '');
    try {
      await this.$axios.put(
        '/api/me/managers/' + payload.slug +'/subscribes',
        payload
      );

      EventBus.$emit('createSnackbar', {
        message: `Subscriptions Updated`,
      });

    } catch (err) {
      commit('setError', err);
    }
  }
};
