export default {
  getWorkers: state => {
    return state.workers;
  },
  getLinks: state => {
    return state.links;
  },
  getMeta: state => {
    return state.meta;
  },
  getWorker: state => {
    return state.worker;
  },
};
