export default {
  clearMedia: (state) => {
    state.mediaMap = {}
  },

  setError: (state, error) => {
    state.error = error;
  },

  toggleData: state => {
    state.hasData = true;
  },

  pushModelMedia: (state, data) => {
    state.mediaMap[data.key] = data.media;
    state.mediaRefresh = new Date();
  },

  pushMedia: (state, data) => {
    Array.from(data.media).forEach(media => {
      const key = media.custom_properties.from;

      if (!state.mediaMap[key]) {
        state.mediaMap[key] = [];
      }

      if (data.multiple) {
        state.mediaMap[key].push(media);
      } else {
        state.mediaMap[key] = media;
      }
    });

    // refresh Media
    state.mediaRefresh = new Date();
  },

  remove: (state, data) => {
    if (data && data.multiple) {
      if (
        Array.isArray(state.mediaMap[data.key]) &&
        state.mediaMap[data.key][data.index]
      ) {
        state.mediaMap[data.key].splice(data.index, 1);
      }
    } else {
      state.mediaMap[data.key] = [];
    }
  },
  uploading: (state, data) => {
    if (data && data.progress == 0) {
      state.uploading.push({id: data.id, progress: data.progress});
    } else {
      state.uploading[data.id].progress = data.progress;
    }
  },
  uploaded: (state, data) => {
    state.uploaded.push(data);
  },
  cancel: (state, data) => {
    state.canceled = data;
  },
  clearUpload: (state, key) => {
    state[key] = [];
  },
  showError: (state, data) => {
    state.showError = data;
  },
};
