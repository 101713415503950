export default {
  setPlans: (state, plans) => {
    const keys = [];
    plans.forEach(plan => {
      plan.description = JSON.parse(plan.description);
      Object.keys(plan.description).forEach(key => {
        if (-1 === keys.indexOf(key)) {
          keys.push(key);
        }
      });
    });
    state.planKeys = keys;
    state.plans = plans;
  },

  setError: (state, err) => {
    state.error = err;
  },

  setInvoices: (state, invoices) => {
    state.invoices = invoices;
  },

  setBilling: (state, billing) => {
    state.billing = billing;
  },
};
