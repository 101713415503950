<template>
  <div class="register-template">
    <div class="register-nav">
      <img
        class="register-logo"
        src="~/assets/images/logo-login.svg"
        alt="SiteCircle"
      />
      <a href="/login" v-if="!isLogin">
        <UiButton class="singup-btn">SIGN IN</UiButton>
      </a>
    </div>
    <div class="register-wrapper">
      <nuxt v-if="hasDict" />
    </div>
    <LoaderComponent />
  </div>
</template>

<script>
import LoaderComponent from '../components/LoaderComponent.vue';

export default {
    data() {
        return {}
    },
    components: {
        LoaderComponent
    },
    mounted() {
        this.$store.dispatch('dict/init');
    },
    computed: {
        hasDict() {
            return this.$store.getters['dict/hasData']
        },

        isLogin() {
            return this.$route.path.indexOf('login') >= 0
        }
    }
}
</script>

<style lang="scss">
@import '~assets/scss/global_vars';

.register-template {
  .register-wrapper {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
  }

  .register-form {
    width: 630px;
    margin: 5rem 0;
    transition: all 0.2s ease-out;
    /*display: flex;*/
    /*justify-content: center;*/

    @media (max-width: $mobileWidth) {
      margin-top: 0;
    }
  }

  .register-nav {
    position: absolute;
    top: 33px;
    width: 100%;
    padding: 0 8vw;
    display: flex;
    justify-content: space-between;

    button {
      width: 180px;
    }

    @media (max-width: $mobileWidth) {
      padding: 0 1rem;
      justify-content: space-between;
      align-items: center;
      top: 24px;
      a {
        margin: 0;
      }
      button {
        width: 108px;
        min-width: 0;
        height: 36px;
      }
    }
  }

  .register-logo {
    @media (max-width: $mobileWidth) {
      height: 26px;
    }
  }

  .registration__step {
    margin-bottom: 60px;
  }

  .form-section {
    margin-bottom: 50px;

    &.upload {
      margin-bottom: 20px;
    }
  }

  h3 {
    color: $dark-main;
    font-size: 22px;
    margin-bottom: 0;
  }
}

.travel-title {
  margin: 50px 0 13px;
  font-size: 22px;
  color: $dark-main;
}

.registration-nav {
  &-container {
    @media (max-width: $mobileWidth) {
      display: flex;
      justify-content: space-between;
    }
  }

  &-button {
    width: 200px;
    height: 55px;

    &:first-of-type {
      margin-right: 20px;
      background-color: rgba(255, 223, 36, 0.5);
    }

    @media (max-width: $mobileWidth) {
      width: 158px;
      &:first-of-type {
        margin-right: 0;
      }
    }
  }
}
</style>
