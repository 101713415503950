<template>
  <div class="invite-template">
    <div class="invite-template__nav">
      <img
        class="invite-template__logo"
        src="~/assets/images/logo-login.svg"
        alt="SiteCircle"
      />
    </div>

    <div class="invite-template__wrapper">
      <nuxt />
    </div>

    <LoaderComponent />
  </div>
</template>

<script>
import LoaderComponent from '../components/LoaderComponent.vue';
export default {
  components: { LoaderComponent },
};
</script>

<style lang="scss">
@import '../assets/scss/global_vars';

.invite-template {
  &__wrapper {
    padding-top: 86px;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    background-color: #fbfbfd;
  }

  &__nav {
    position: absolute;
    top: 33px;
    width: 100%;
    padding: 0 8vw;
    display: flex;
    justify-content: center;

    @media (max-width: $mobileWidth) {
      padding: 0 1rem;
      align-items: center;
      top: 24px;
    }
  }

  &__logo {
    @media (max-width: $mobileWidth) {
      height: 26px;
    }
  }

  .invitation {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 2.5rem;

    &--background {
      position: relative;

      &:after {
        content: '';
        z-index: 0;
        position: fixed;
        min-height: 100vh;
        min-width: 100vw;
        background-image: url('~@/assets/images/approval-bg.png');
        background-repeat: no-repeat;
        background-position: center 35vh;
      }
    }

    &__wrapper {
      flex-grow: 1;
      position: relative;
      z-index: 1;
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 1;
    }

    &__button {
      width: 90%;
      max-width: 275px;

      &--full {
        position: relative;
        font-size: 20px;
        color: $white !important;
        font-weight: 500;
        line-height: 1.2;
        max-width: unset;
        width: 100%;
        padding: 20px;
        height: unset;
        overflow-x: none;

        background-color: $inform;

        box-shadow: 0 -1px 8px 0 rgba(0, 161, 254, 0.39);
      }
    }

    &__text {
      font-size: 20px;
      line-height: 30px;
      font-weight: 400;
    }
  }
}
</style>
