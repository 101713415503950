export default {
  enableLoader: (state, payload) => {
    state.loading = true;
    // state.progress =
    //   payload && ![false, null, undefined].includes(payload.progress)
    //     ? payload.progress
    //     : false;
  },
  disableLoader: state => {
    state.loading = false;
    // state.progress = false;
  },
};
