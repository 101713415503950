export default {
  getAll: state => {
    return state.managers;
  },
  getManager: state => {
    return state.manager;
  },
  getError: state => {
    return state.error
  }
};
