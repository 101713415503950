<template>
  <div v-if="'UiFileupload' === data.selector">
    <div
      :class="{
        custom: data.custom === true,
        'avatar-wrapper': data.avatar === true,
        'card-wrapper': data.card === true,
      }"
    >
      <div :class="`image-${data.name}-preview`" v-if="data.files.length">
        <div v-for="(file, index) in data.files"
          class="file-preview__wrapper"
          :class="{
            'avatar-preview': data.avatar === true,
            'card-preview': data.card === true,
          }"
        >
          <div class="file-preview__container">
            <img
              v-if="file && file.mime_type && 0 === file.mime_type.indexOf('image/')"
              :alt="file.name"
              class="page__demo-preview-image"
              :width="!data.card ? '100px' : ''"
              :src="file.full_url"
              @click="handleRemoveFile(index)"
            />
          </div>
        </div>
      </div>

      <UiFileupload
        :name="data.name"
        :invalid="data.invalid"
        :multiple="data.multiple || false"
        :avatar="data.avatar"
        :card="data.card"
        :custom="data.custom"
        :fileTypes="data.fileTypes"
        id="avatar"
        ref="avatarButton"
        :error="data.errors"
        :accept="data.accept"
        @change="handleAddFiles"
        v-bind="attrs"
      >
        <div v-if="!data.files || !data.files.length || data.files[0] === '' || !data.files[0].hasOwnProperty('id') || !data.files[0].id">
          <slot></slot>
        </div>
      </UiFileupload>
    </div>

    <button
      @click="
        handleRemoveFile(
          'undefined' !== typeof data.files[0] &&
            data.files[0].hasOwnProperty('id')
            ? data.files[0].id
            : null
        )
      "
      v-if="!data.card"
      style="
        position: absolute;
        z-index: 2;
        top: -5px;
        background-color: transparent;
        border: none;
        left: calc(50% + 15px);
      "
    >
      <!--<img src="~/assets/images/edit.svg" class="file-icon"/>-->
    </button>
  </div>

  <UiSelect
    v-else-if="'UiSelect' === data.selector"
    v-model="data.value"
    :options="options || data.options"
    :error="data.errors"
    :keys="data.keys || { label: 'value', value: 'id' }"
    :multiple="data.multiple || false"
    :hasSearch="data.hasSearch || false"
    :invalid="data.invalid"
    :limit="data.limit || 10"
    :label="data.label"
    v-bind="attrs"
    :readonly="data.readonly || false"

  >
    <slot></slot>
  </UiSelect>

  <UiTextbox
    v-else-if="'UiTextbox' === data.selector"
    v-model="data.value"
    :error="data.errors"
    :errorActive="!!data.errors"
    :name="data.name || null"
    :invalid="data.invalid"
    :type="data.type"
    :min="data.min"
    :max="data.max"
    :mask="data.mask"
    :multiLine="data.multiLine"
    :rows="data.rows"
    :disabled="data.disabled || false"
    :readonly="data.readonly || false"
    v-bind="attrs"
  >
    <slot>
      {{data.label}}
    </slot>
  </UiTextbox>

  <UiDatepicker
    v-else-if="'UiDatepicker' === data.selector"
    v-model="data.value"
    :error="data.errors"
    :invalid="data.invalid"
    orientation="landscape"
    :min-date="data.minDate || null"
    :max-date="data.maxDate || null"
    :show-header="data.showHeader"
    :disabled="data.disabled || false"
    v-bind="attrs"
  >
    <slot></slot>
  </UiDatepicker>

  <UiRadioGroup
    v-else-if="'UiRadioGroup' === data.selector"
    v-model="data.value"
    :options="options || data.options"
    :name="data.name"
    :disabled="data.disabled || false"
    v-bind="attrs"
  >
    <slot></slot>
  </UiRadioGroup>

  <UiCheckbox
    v-else-if="'UiCheckbox' === data.selector"
    v-model="data.value"
    :options="data.options"
    :name="data.name"
    v-bind="attrs"
  >
    <slot></slot>
  </UiCheckbox>

  <UiSwitch
    v-else-if="'UiSwitch' === data.selector"
    v-model="data.value"
    :name="data.name"
    v-bind="attrs"
  >
    <slot></slot>
  </UiSwitch>

  <UiHidden
    v-else-if="'UiHidden' === data.selector"
    v-model="data.value"
    v-bind="attrs"
  >
    <slot></slot>
  </UiHidden>

  <UiSlider
    v-else-if="'UiSlider' === data.selector"
    v-model="data.value"
    :showMarker="data.showMarker"
    :snapToSteps="data.snapToSteps"
    :time="data.time"
    :min="data.min"
    :step="data.step"
    :max="data.max"
    :experience="data.experience"
    v-bind="attrs"
  >
    <slot></slot>
  </UiSlider>
</template>

<script>
// import moment from 'moment';

export default {
  props: ['data', 'options'],
  name: 'FormUiElement',
  methods: {
    handleAddFiles(fileList, event) {
      const fieldName = event.target.name;
      this.$store.dispatch('media/post', {
        fileList,
        fieldName,
        multiple: this.data.multiple || false,
      });
    },
    handleRemoveFile(index, event) {
      const multiple = this.data.multiple || false;

      this.$store.commit('media/remove', {
        index: index,
        key: this.data.name,
        multiple: multiple,
      });

      if (multiple) {
        this.data.files.splice(index, 1);
      } else {
        this.data.files = [];
      }
    },
  },
  computed: {
    attrs() {
      const { options, ...attrs } = this.$attrs;
      if(this.data.required){
        attrs.required = 'required';
      }
      return attrs;
    },
    filesRefresh() {
      if ('UiFileupload' === this.data.selector) {
        return this.$store.getters['media/getMediaRefresh'];
      }
    },
    fileError() {
      if ('UiFileupload' === this.data.selector) {
        return this.$store.getters['media/getError'];
      }
    },
  },
  watch: {
    filesRefresh() {
      const mediaMap = this.$store.getters['media/getMediaMap'];
      const media = mediaMap[this.data.name];

      if ('undefined' !== typeof media) {
        if (media.hasOwnProperty('id')) {
          this.data.files = [media];
        } else {
          this.data.files = media;
        }
      }
    },
    fileError(next) {
      const [field, error] = next.split('|');

      if (field === this.data.name) {
        this.data.errors = error;
        this.data.invalid = !!error;
      }
    },
  },
};
</script>

<style lang="scss">
*[readonly] {
  background-color: #ececec;
}

.ui-checkbox,
.ui-radio-group {
  margin-top: 20px;
}

.ui-radio-group {
  margin-top: 15px;
}

.avatar-wrapper {
  margin: 0 auto;
  position: relative;
  text-align: center;
  max-width: 100px;
  max-height: 100px;
  min-height: initial !important;
  border-radius: 9px;
  overflow: hidden;

  .avatar-preview {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
}

.card {
  &-wrapper {
    position: relative;
    text-align: center;
    margin-bottom: 50px;

    .file-preview {
      &__filename {
        display: none;
      }

      &__wrapper {
      }

      &__container {
        min-height: 145px;
        height: 145px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        overflow: hidden;
        position: relative;
      }
    }
  }
  &-preview {
    position: absolute;
    z-index: 1;
    max-width: initial !important;
    width: calc(100% - 4px);
    margin-bottom: 0 !important;
    top: 12px;
    left: 2px;
  }
  .page__demo-preview-image {
    position: absolute;
    object-fit: cover;
  }
}
.custom {
  .file-preview {
    &__filename {
      max-width: 100px;
      display: inline-block;
      overflow: hidden;
      white-space: normal;
      text-overflow: ellipsis;
    }

    &__wrapper {
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      max-width: 100px;
      overflow: hidden;
      top: 0;
      left: 0;
      width: 100%;
      border-radius: 10px;
    }

    &__container {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;
    }
  }

  .image-batchFile-preview {
    margin-bottom: 30px;
  }

  .page__demo-preview-image {
    position: absolute;
    object-fit: cover;
    display: block;
    height: 100%;
    width: 100%;
  }
}
.photo {
  padding-top: 16px;
  .ui-fileupload, .file-preview__container, .custom.card-wrapper {
    max-height: 54px;
    min-height: 54px;
    margin-bottom: 0;
  }
}
</style>
