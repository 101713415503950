<template>
    <button class="btn btn-brand"
            :class="buttonClass"
            :disabled="disabled"
    >
      <span v-if="!inProgress" >
        <i class="icon" :class="icon" v-if="showIcon"></i>
      </span>
      <span v-else class="pr-3">
        <UiProgressCircular
          :type="'indeterminate'"
          :progress="0"
          color="brand"
          :size="20"
          :stroke="5"
        />
      </span>
      <slot></slot>
    </button>
</template>
<script>
export default {
    props: {
        icon: {
            type: String,
            default: ''
        },
        buttonClass: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        inProgress: {
          type: Boolean,
          default: false
        },
        showIcon: {
            type: Boolean,
            default: true
        }
    }
}
</script>
<style lang="scss">
    .clear {
        i {
            display: none;
        }
    }
</style>
