export default {
    getWidgets: state => {
        return state.widgets;
    },
    getError: state => {
        return state.error;
    },
    getErrorDublicated: state => {
        return state.errorDublicated;
    },
    getExcelUrl: state => {
        return state.excelUrl;
    } 
}