export default {
  getAll: state => {
    return {
      jobs: state.jobs,
      links: state.links,
      meta: state.meta,
    };
  },
  getJobs: state => {
    return state.jobs ? state.jobs : [];
  },
  getJob: state => {
    return state.job;
  },
  getError: state => {
    return state.error;
  },
};
