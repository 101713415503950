export const delay = (ms = 100) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), ms);
  })
}
// Hardcoded UK
export const PHONE_PREFIX = '+44'
export const phoneNumberAddPrefix = (phoneNumber) => {
  // remove if passed phonenumber already has prefix
  phoneNumber = phoneNumberRemovePrefix(phoneNumber);
  phoneNumber = `${PHONE_PREFIX} ${phoneNumber}`
  return phoneNumber;
}

export const phoneNumberRemovePrefix = (phoneNumber) => {
  phoneNumber = phoneNumber.replace(new RegExp(`\\${PHONE_PREFIX}` + ' '), "");
  phoneNumber = phoneNumber.replace(new RegExp(`\\${PHONE_PREFIX}`), "");
  return phoneNumber
}
