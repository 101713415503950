export default {
  data() {
    return {
      windowWidth: 0,
    };
  },
  mounted() {
    window.addEventListener('resize', this.getWindowWidth);
    this.getWindowWidth();
  },
  methods: {
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;
    },
  },
  computed: {
    isMobile () {
      return this.windowWidth < 768
    },
    isDesktop () {
      return this.windowWidth >= 768
    }
  }
};
