export default {
  getPlans: state => {
    return state.plans;
  },
  getPlanKeys: state => {
    return state.planKeys;
  },
  getError: state => {
    return state.error;
  },
  getInvoices: state => {
    return state.invoices;
  },

  getBilling: state => {
    return state.billing;
  },
};
