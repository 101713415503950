export const isDebug = () => {
  return process.env.DEBUG === true || process.env.DEBUG === "true";
}
export const isLocalhost = () => {
  return window.location.hostname === "localhost";
}
export const getWebViewInjectedConfig = () => {
  const config = window.reactNativeInjectConfig;
  if (config) {
    if (!config.deviceId || config.token === undefined) {
      console.warn("Invalid format of `reactNativeInjectConfig` from parent!!!")
    }
  }
  // 'typescript'....
  const {token, deviceId, nativeConfig, ...rest} = config;
  return {token, deviceId, nativeConfig, ...rest};
}
export const getDeviceId = () => {
  if (!isInWebView()) {
    throw new TypeError("Invalid usage of getDeviceId. Available only in webview");
  }
  return getWebViewInjectedConfig().deviceId;
}
export const isInWebView = () => {
  // return true;
  /** Native object injected by React Native **/
  if (!window.ReactNativeWebView) {
    return false;
  }
  /** Config injected by parent app **/
  if (!window.reactNativeInjectConfig) {
    return false;
  }
  return true;
}
// some parts of debug helpers also rely on localstorage key 'test'
export const isTestDebug = () => {
  return isDebug() || localStorage.getItem('sitecircle-test-debug');
}

// is in flow that should be only displayed on mobile. /register/worker
/**
 *
 * @param context Route
 * @returns {boolean}
 */
export const isMobileWorkerRegisterFlow = (context) => {
  // Detect if we are on new mobile worker register flow:
  // - New register flow: /register/worker
  // - Old register flow: /register/worker/step/<index>
  return context.path.indexOf(getMobileWorkerRegisterFlowPath()) !== -1
    && context.path.indexOf('/register/worker/step') === -1
}

export const getMobileWorkerRegisterFlowPath = () => {
  return '/register/worker';
}

export const redirectToMobileWorkerRegisterFlow = (context) => {
  // const path = {
  //   path: getMobileWorkerRegisterFlowPath(),
  //   query: {r: Math.random()}
  // };
  // return context.redirect(path)
  window.location.href = getMobileWorkerRegisterFlowPath() + '?r=' + Math.random()
  // window.location.reload();
}
