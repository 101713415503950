import { EventBus } from '~/plugins/event-bus';
import {disableLoader, enableLoader} from "@/helpers/loader";

export default {
  async new({ commit }, offer) {
    enableLoader()
    try {
      commit('setError', '');
      const response = await this.$axios.post(`/api/me/job-offers`, offer);
      disableLoader()
      return response;
    } catch (err) {
      commit('setError', err);
      disableLoader()
      return err;
    }
  },

  async accept({ commit, dispatch }, payload) {
    enableLoader()
    const { id, rams_accepted } = payload;
    try {
      commit('setError', '');
      await this.$axios.put(`api/me/job-offers/${id}/accept`, {
        rams_accepted: rams_accepted,
      });
      EventBus.$emit('closeDrawer');
      EventBus.$emit('createSnackbar', {
        message: `Job Offer have been accepted`,
      });
    } catch (err) {
      commit('setError', err);
    }
    disableLoader()
  },

  async reject({ commit, dispatch }, payload) {
    enableLoader()
    const { id } = payload;
    try {
      commit('setError', '');
      await this.$axios.put(`api/me/job-offers/${id}/reject`);
      EventBus.$emit('closeDrawer');
      EventBus.$emit('createSnackbar', {
        message: `Job Offer have been rejected`,
      });
    } catch (err) {
      EventBus.$emit('closeDrawer');
      commit('setError', err);
    }
    disableLoader()
  },
};
