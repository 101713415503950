<template>
  <nav class="container-fluid top-bar" v-if="!shouldHide">
    <div class="top-bar__link popover-trigger">
      <div class="tob-bar__icon row justify-content-center" :class="{ hasNew: newNotifications.length }">
        <img src="~/assets/images/bell.svg" alt="Notifications" />
      </div>

      <span>
        Notifications
      </span>

      <ui-popover class="profile-information-popover" contain-focus :dark="false" position="bottom-start">
        <NotificationsList />
      </ui-popover>
    </div>

    <div class="top-bar__link popover-trigger">
      <div class="tob-bar__icon row justify-content-center">
        <img src="~/assets/images/profile.svg" alt="Profile" />
      </div>

      <span class="logged-in-user">
        <span v-if="getUser('default_role') === 'OWNER'">
          {{
            company
              ? company.name
              : `${getUser('first_name')} ${getUser('last_name')}`
          }}
        </span>

        <span v-else>
          {{ getUser('first_name') }} {{ getUser('last_name') }}
        </span>
      </span>

      <ui-popover class="profile-information-popover" contain-focus :dark="false" position="top-end">
        <p class="popover-item">
          <nuxt-link to="/settings/account">
            Account
          </nuxt-link>
        </p>

        <p class="popover-item">
          <nuxt-link
            :to="`${isWorker || isSecurityGuard ? '/profile/edit' : '/settings/company'}`"
          >
            {{ isWorker || isSecurityGuard ? 'Profile Details' : 'Company Details' }}
          </nuxt-link>
        </p>

        <p class="popover-item" v-if="isOwner && !isMainContractor">
          <nuxt-link to="/settings/billing">Billing</nuxt-link>
        </p>

        <p class="popover-item alert">
          <a @click="handleLogout()" class="link logout-button">Log Out</a>
        </p>
      </ui-popover>
    </div>
  </nav>
</template>

<script>
import AwesomeIcon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/cog';
import 'vue-awesome/icons/bell';
import 'vue-awesome/icons/user';

import NotificationsList from '@/components/notifications/NotificationsList';
import DropDown from './DropDown';

export default {
  components: {
    AwesomeIcon,
    DropDown,
    NotificationsList,
  },
  data() {
    return {
      profileInfo: false,
    };
  },
  mounted() {
    this.$store.dispatch('companies/init');
  },
  computed: {
    company() {
      return this.$store.getters['companies/getCompany'];
    },

    shouldHide () {
      return (this.isSecurityGuard || this.isWorker) && !!window.ReactNativeWebView
    },

    role() {
      return this.$store.getters['auth/getUserRole'];
    },

    isWorker() {
      return this.$store.getters['auth/isWorker'];
    },

    isOwner() {
      return this.$store.getters['auth/isOwner'];
    },

    isMainContractor () {
      if (!this.company) return false

      return !!(this.company && this.company['main_contractor'])
    },

    isSecurityGuard() {
      return this.$store.getters['auth/isSecurityGuard'];
    },

    newNotifications() {
      return this.$store.getters['notifications/getNew'];
    },
  },
  methods: {
    getUser(key) {
      return (
        (this.$store.state.auth.user && this.$store.state.auth.user[key]) || ''
      );
    },

    handleLogout() {
      this.$store.dispatch('auth/logout');
    },
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/global_vars';

.top-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: $topBarHeight;
  background-color: $white;
  min-width: 100%;
  border-bottom: solid 1px $border-light-grey;
  box-shadow: 0 10px 34px 0 rgba(210, 210, 210, 0.09);
  position: fixed;
  right: 0;
  top: 0;
  z-index: 3;
  outline: none;
  @media (max-width: $mobileWidth) {
    min-height: $topBarHeightMobile;
  }

  &__link {
    padding: 0 1rem;
    text-transform: capitalize;
    font-size: 13px;
    font-weight: 500;

    &:hover {
      cursor: pointer;
    }

    .tob-bar__icon {
      margin-bottom: 10px;

      @media (max-width: $mobileWidth) {
        margin-bottom: 0;
      }

      &.hasNew {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          $size: 10px;
          display: block;
          height: $size;
          width: $size;
          border-radius: 50%;
          background-color: $alert;
          top: 1px;
          right: 41%;

          @media (max-width: $mobileWidth) {
            right: 19%;
          }
        }
      }
    }

    &.popover-trigger {
      margin-right: 1rem;
      @media (max-width: $mobileWidth) {
        span {
          display: none;
        }
      }
    }
  }

  .logged-in-user {
    position: relative;
    margin-right: 0.75rem;

    &:after {
      content: '';
      position: absolute;
      right: -15px;
      top: 40%;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid black;
      transition: 0.3s;
    }

    &.show {
      &:after {
        transform: rotate(180deg) translateY(50%);
      }
    }
  }

  .popover-trigger:focus {
    outline: none;
  }
}
</style>
