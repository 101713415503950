export default {
  getMediaRefresh: state => {
    return state.mediaRefresh;
  },
  getMediaMap: state => {
    return state.mediaMap;
  },
  getError: state => {
    return state.error;
  },
  getUploading: state => {
    return state.uploading;
  },
  getUploaded: state => {
    return state.uploaded;
  },
  errorShow: state => {
    return state.showError;
  },
  getProgress: state => {
    return state.progress;
  },
  getCanceled: state => {
    return state.canceled;
  }
};
