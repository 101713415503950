export default {
  setAssignments: (state, assignments) => {
    state.assignments = assignments.sort(
      (a, b) => a.rams_accepted - b.rams_accepted
    );
  },

  setAssignment: (state, assignment) => {
    state.assignment = assignment;
  },

  setLinks: (state, links) => {
    state.links = links;
  },

  setMeta: (state, meta) => {
    state.meta = meta;
  },

  toggleData: state => {
    state.hasData = true;
  },

  setError: (state, error) => {
    state.error = error;
  },
};
