<template>

  <MessageBox>
    We've send you a new code.
    <br>
    {{ title }}<br/>
    Please call Sitecircle help<br/>
    <a href="tel:+44123123123" class="blue-link">+44 123 123 123</a><br/>
    or<br/>
    email us you number at<br/>
    <a href="mailto:help@sitecircle.co.uk" class="blue-link">help@sitecircle.co.uk</a><br/>
    we will call you back soon.
  </MessageBox>
</template>
<script>
import MessageBox from "@/components/WorkerRegistration/MessageBox";

export default {
  name: "HelpMessageBox",
  components: {MessageBox},
  props: {
    title: {
      type: String,

    }
  }
}
</script>
