import { UploadProcess } from '@/etc/upload-process';
import {disableLoader, enableLoader} from "@/helpers/loader";

export default {
  async post({ commit, state }, data) {
    enableLoader()
    const formData = new FormData();
    const { fileList, fieldName } = data;

    formData.append('file_key', fieldName);

    Array.from(Array(fileList.length).keys()).map(x => {
      formData.append(fieldName + '[]', fileList[x], fileList[x].name);
    });

    try {
      const media = await this.$axios({
        method: 'post',
        url: `/api/media/upload`,
        data: formData,
      });
      commit('setError', fieldName + '|');
      commit('pushMedia', {
        media: media.data.data,
        multiple: data.multiple || false,
      });
    } catch (err) {
      commit('setError', fieldName + '|' + err.message);
    }
    disableLoader()
  },

  async postChunked({ commit, state }, { fileList, fieldName, multiple }) {
    commit(
      'loader/enableLoader',
      {
        progress: 0,
      },
      { root: true }
    );

    try {
      commit('showError', false);
      commit('setError', '');
      const toTransfer = new Array(fileList.length).fill(0);
      const transferedBytes = new Array(fileList.length).fill(0);
      const onProgress = (fileIndex, status) => {
        toTransfer[fileIndex] = status.totalSize;
        transferedBytes[fileIndex] = status.transferedBytes;

        if (toTransfer.length > 0 && transferedBytes.length > 0) {
          const size = toTransfer.reduce((prev, curr) => prev + curr, 0);
          const transfered = transferedBytes.reduce(
            (prev, curr) => prev + curr,
            0
          );

          commit(
            'loader/enableLoader',
            {
              progress: Math.round((transfered / size) * 100),
            },
            { root: true }
          );
        }
      };

      const processes = [];
      Array.from(fileList).forEach((file, index) => {
        file.name.replace(/\s+/g, '-');
        commit('uploading', {id: index, progress: 0});
        processes.push(
          new UploadProcess(file, index, fieldName, this.$axios, onProgress)
            .then(fileArr => {
              fileArr[0].name = fileArr[0].name.replace(/\s+/g, '-');
                if(index !== state.canceled){
                  commit('pushMedia', {
                    media: fileArr,
                    multiple: multiple || false,
                  });
                  commit('uploaded', {id: index, progress: 100});
                }
              })
          )
      });
      await Promise.all(processes);
      commit('setError', fieldName + '|');
    } catch (err) {
      if (!err.status) {
        commit('showError', true);
        let message = 'Unable to upload file';
        
        if (err.message) {
          const key = Object.keys(err.errors)[0];
          message = err.errors[key][0];
        }

        commit('setError', message);
      }
    }
    commit('clearUpload', 'uploading');
    commit('clearUpload', 'uploaded');
    commit('cancel', false);
    disableLoader()
  },
};
