export default {
  getAll: state => {
    return {
      assignments: state.assignments,
      links: state.links,
      meta: state.meta,
    };
  },

  getAssignments: state => {
    return state.assignments;
  },

  getAssignment: state => {
    return state.assignment;
  },

  getError: state => {
    return state.error;
  },

  gettingToWorkTypes: state => {
    return state.gettingToWorkTypes;
  }
};
