<template>
  <div style="text-align: center;" class="message-box">
    <slot/>
  </div>
</template>
<script>

export default {
  name: "MessageBox",
  computed: {},
  mounted() {
  },
  methods: {
    fetchNextRegistrationStep() {

    },
    handleStepComplete() {

    }
  }
}
</script>
<style lang="scss" scoped>
.message-box {
  background-color: #f5f5f5;
  color: #707070;
  padding: 20px 30px;
  line-height: 1.5;
}
</style>
