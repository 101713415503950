<template>
  <header class="site-logo">
    <nuxt-link class="link main__link" to="/" v-if="windowWidth >= 500">
      <img src="~/assets/images/logo.svg" alt="SiteCircle" class="logo" />
      <span class="mode">{{ modeFromRole }}</span>
    </nuxt-link>
    <div class="link main__link" v-if="windowWidth < 500">
      <img src="~/assets/images/logo.svg" alt="SiteCircle" class="logo" />
      <span class="mode">{{ modeFromRole }}</span>
    </div>
  </header>
</template>

<script>
import checkWidth from '../mixins/checkWidth';

export default {
  props: ['role'],
  mixins: [checkWidth],
  computed: {
    modeFromRole() {
      switch (this.role) {
        case 'OWNER':
          return 'Company';
        case 'MANAGER':
          return 'Manager';
        case 'WORKER':
          return 'Worker';
        default:
          return '';
      }
    },
  },
};
</script>

<style lang="scss">
@import '../assets/scss/global_vars';

.site-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: $topBarHeight;
  padding-left: 15px;
  font-size: 36px;
  //height: 36px;
  background-color: $logo-background;
  transition: 0.3s;

  @media (max-width: $mobileWidth) {
    min-height: 0;
    height: $topBarHeightMobile;
  }

  .link {
    color: $brand;
    display: flex;
    position: relative;
    width: 30px;
    overflow: hidden;
    transition: 0.3s;

    .mode {
      position: absolute;
      font-size: 12px;
      bottom: -10px;
      left: 40px;
      font-weight: $fontBolder;
      letter-spacing: 0.5px;
      display: none;
    }

    .logo {
      height: 36px;
    }
  }
}
</style>
